import {
  Button,
  Chip,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import React from 'react';

interface Props {
  data: any;
  labelText?: string;
  placeHolderText?: string;
}
export default function DisplayTagsInput(props: Props) {
  const { data, labelText, placeHolderText } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <FormControl fullWidth className="customAutocomplete">
        <FormLabel className="formLabel">{labelText}</FormLabel>
        <div
          className={`displayTags ${data && data.length < 0 ? 'disabled' : ''}`}
          onClick={(e) => handleClick(e)}
        >
          {data && data.length > 0 ? (
            <>
              <Chip label={data && data[0].setName} color="secondary" />
              {data.length > 1 && (
                <IconButton>+ {data && data.length}</IconButton>
              )}
            </>
          ) : (
            <p className="placeholder">{placeHolderText}</p>
          )}
        </div>
      </FormControl>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 0,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className="tagsInputMenu"
        sx={{ width: anchorEl && anchorEl.offsetWidth + 30 }}
      >
        {data &&
          data.map((v: any, i: any) => (
            <MenuItem onClick={handleClose}>{v.setName}</MenuItem>
          ))}
      </Menu>
    </>
  );
}
