import {
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Images from '../../../Utils/images';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TablePagination from '../../../Components/Pagination';
import React, { useEffect, useState } from 'react';
import InputField from '../../../Components/Input';
import CustomSelect from '../../../Components/CustomSelect';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../helpers/contants';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../redux/store';
import {
  EditRentOptions,
  EditRentOptionsWithEditAndStatus,
  EditedProductOrder,
  OrderEditOptionValues,
  OrderRentEditOptionValues,
  ModalMode,
} from '../../../types';
import Loading from '../../../Components/Loading';
import { AIT } from '../../../Utils/Localization';
import dayjs from 'dayjs';
import {
  ORDER_PRODUCT_STATUS,
  ORDER_PRODUCT_TYPES,
  PRODUCT_TABS,
  PRODUCT_TYPES,
} from '../../../Utils/constantData';
import {
  getAndOrderDetails,
  getOrderProductsList,
  getOrderStatus,
  rentApproveReject,
  setDeliveryAddressModalStatus,
  updateRentQuantity,
  updateStatus,
} from '../../../redux/order.slice';
import RejectRentDailog from './RejectRentDailog';
import { updatePaginationPage } from '../../../redux/common.slice';
import NoData from '../../../Components/Nodata';
import {
  downloadZipFromS3,
  formatPrice,
  getTimeWithTimeZoneAbbreviation,
} from '../../../Utils/helperFns';
import { notify } from '../../../Utils/toastify';

const StatusMap = new Map();
StatusMap.set('Accepted', 'ACCEPTED');
StatusMap.set('In Transit', 'INTRANSIT');
StatusMap.set('Shipped', 'SHIPPED');
StatusMap.set('Delivered', 'DELIVERED');
StatusMap.set('Service Started', 'STARTED');
StatusMap.set('Service Ended', 'ENDED');
StatusMap.set('Completed', 'COMPLETED');

export default function RentTable() {
  const { id } = useParams();
  const [openReject, setOpenReject] = React.useState(false);
  const tableRef: any = React.useRef(null);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { rentProdctsList } = useAppSelector((state: any) => state.order);
  const { loading } = useAppSelector((state: any) => state.common);
  const [editedItem, setEditedItem] = useState<EditedProductOrder | null | any>(
    null,
  );
  const [quantity, setQuantity] = useState<any>(0);
  const [noteModel, setNoteModel] = useState<{
    status: boolean;
    notes: string;
  }>({ status: false, notes: '' });
  const [selectStatus, setSelectStatus] = useState('');
  const dispatch: any = useDispatch();
  const { permissionToAdd } = useAppSelector((state) => state.auth);
  const [intervalId, setIntervalId] = React.useState<any>(null);
  const [timeoutId, setTimeoutId] = useState<any>(null);
  const [pollingStatus, setPollingStatus] = React.useState<any>(false);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRejectClose = (type: string) => {
    setOpenReject(!openReject);
    if (type && id) {
      getOrderProductsList(
        id,
        ORDER_PRODUCT_TYPES.rent,
        `&pageNo=${1}&limit=5`,
      );
    }
  };
  const { t: tC } = useTranslation('translation', {
    keyPrefix: 'Common',
  });
  const { t } = useTranslation('translation', {
    keyPrefix: 'orders.detail',
  });

  const RentstatusOptions = [
    // `${tC('accepted')}`,
    `${tC('shipped')}`,
    `${tC('in_transit')}`,
    `${tC('delivered')}`,
    `${tC('service_started')}`,
    `${tC('service_end')}`,
  ];

  const ServiceStatusOptions = [
    `${tC('service_started')}`,
    `${tC('service_end')}`,
    `${tC('completed')}`,
  ];

  const handlePageChange = (page: number) => {
    updatePaginationPage(page);

    id &&
      getOrderProductsList(
        id,
        ORDER_PRODUCT_TYPES.rent,
        `pageNo=${page}&limit=5`,
      );
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    product: any,
    index: number,
  ) => {
    setEditedItem({
      index,
      product,
      mode: null,
    });
    setSelectStatus(ORDER_PRODUCT_STATUS[product.status]);
    setQuantity(product.qty);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuSelect = (value: OrderRentEditOptionValues) => {
    editedItem &&
      setEditedItem((prev: any) => {
        if (prev) {
          prev['mode'] = value;
        }
        return prev;
      });

    if (value === OrderRentEditOptionValues.edit_quantity) {
      if (tableRef.current) {
        tableRef.current.scrollLeft -= 600; // Adjust scroll amount as needed
      }
    }

    if (value === OrderRentEditOptionValues.reject) {
      setOpenReject(!openReject);
    } else if (value === OrderRentEditOptionValues.approve) {
      rentApproveReject(
        { id: editedItem?.product?._id, status: 'ACCEPTED' },
        (data: any) => {
          if (id && data) {
            getOrderProductsList(
              id,
              ORDER_PRODUCT_TYPES.rent,
              `pageNo=${1}&limit=5`,
            );
            setEditedItem(null);
          }
        },
      );
    } else if (value === OrderRentEditOptionValues.view_workheet) {
      navigate(ROUTES.ORDERS_WORKSHEET + `?id=${editedItem?.product?._id}`, {
        state: { id: editedItem?.product?._id, orderId: id },
      });
    }

    setAnchorEl(null);
  };

  const startPolling = (orderId: string, cb?: any) => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    const id: any = setInterval(() => {
      getOrderStatus(orderId, (status: string) => {
        if (['STARTED', 'ENDED', 'COMPLETED'].includes(status)) {
          clearInterval(intervalId);
          clearTimeout(timeoutId);
          cb();
        } else if (status === 'PROCESSING') {
          setPollingStatus(true);
          setEditedItem(null);

          return;
        }
      });
    }, 3000);
    setIntervalId(id);

    // Set a timeout to clear the interval after 1 minute
    const timeout: any = setTimeout(() => {
      clearInterval(id);
    }, 20000);
    setTimeoutId(timeout);
  };

  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [intervalId, timeoutId]);

  const handleEditQuantityAndStatus = () => {
    if (editedItem?.mode === OrderRentEditOptionValues.edit_quantity) {
      updateRentQuantity(
        {
          id: editedItem?.product?._id,
          qty: +quantity,
        },
        (data: any) => {
          if (id && data) {
            getOrderProductsList(
              id,
              ORDER_PRODUCT_TYPES.rent,
              `pageNo=${1}&limit=5`,
            );
            setEditedItem(null);
          }
        },
      );
    } else if (editedItem?.mode === OrderRentEditOptionValues.edit_status) {
      const payload = {
        // orderId: editedItem?.product?.orderId,
        orderId: editedItem?.product?._id,
        // vendorOrderId: editedItem?.product?.vendorOrderId,
        // productId: editedItem?.product?.productId,
        status: StatusMap.get(selectStatus),
      };

      setPollingStatus(true);
      updateStatus(
        payload,
        (data: any) => {
          if (id && data) {
            startPolling(editedItem?.product?._id, () => {
              notify('Status has been successfully changed', 'success');
              getAndOrderDetails(id);
              setPollingStatus(false);
              getOrderProductsList(
                id,
                ORDER_PRODUCT_TYPES.rent,
                `pageNo=${1}&limit=5`,
              );
            });
          }
        },
        () => {
          setPollingStatus(false);
        },
      );
    }
  };

  const statusDisable = (product: any, item: string) => {
    const allowedStatuses: any = {
      SHIPPED: ['Shipped'],
      INTRANSIT: ['Shipped', 'In Transit'],
      DELIVERED: ['Shipped', 'In Transit', 'Delivered'],
      ACCEPTED: ['Service Ended', 'Service Ended', 'Completed'],
      STARTED: [
        'Shipped',
        'In Transit',
        'Delivered',
        'Service Started',
        'Completed',
      ],
      ENDED: ['Service Started', 'Service Ended'],
    };

    return allowedStatuses[product.status]?.includes(item);
  };

  const handleNoteModel = () =>
    setNoteModel({ ...noteModel, status: false, notes: '' });

  const showPagination = Boolean(
    rentProdctsList &&
      !loading &&
      rentProdctsList.hasOwnProperty('totalPage') &&
      rentProdctsList.totalPage > 1 &&
      rentProdctsList.data &&
      rentProdctsList.data.length > 0,
  );

  const handleDownload = (attachments: any, name: string, i?: number) => {
    let urls = attachments?.map((item: any) => item?.url);

    if (urls?.length > 0) {
      downloadZipFromS3(urls, name);
    }
  };

  return (
    <div className="reelTableContainer">
      <TableContainer
        component={Paper}
        elevation={0}
        className={`reelTable w__140 ${
          permissionToAdd ? 'sticky_column' : ''
        } `}
        ref={tableRef}
      >
        <Table sx={{ minWidth: 650 }} aria-label="table" stickyHeader>
          {Boolean(
            !rentProdctsList?.data || rentProdctsList?.data?.length > 0,
          ) && (
            <TableHead>
              <TableRow>
                <TableCell>{tC('image')}</TableCell>
                <TableCell align="left">{t('product_name')}</TableCell>
                <TableCell align="center" sx={{ maxWidth: '110px' }}>
                  {tC('qty')}
                </TableCell>
                <TableCell align="left" sx={{ minWidth: '250px' }}>
                  {tC('delivery_address')}
                </TableCell>
                <TableCell align="left" sx={{ minWidth: '220px' }}>
                  {t('start_date_time')}
                </TableCell>
                <TableCell align="left" sx={{ minWidth: '220px' }}>
                  {t('end_date_time')}
                </TableCell>
                <TableCell align="center">{t('shift_length')}</TableCell>
                <TableCell align="left">{t('shift_coverage')}</TableCell>
                <TableCell align="left">{tC('notes')}</TableCell>
                <TableCell align="left" sx={{ minWidth: '200px' }}>
                  {t('order_date')}
                </TableCell>
                <TableCell align="center">{tC('unit_price')}</TableCell>
                <TableCell align="center">{tC('sub_total')}</TableCell>

                <TableCell align="center" sx={{ minWidth: '160px' }}>
                  {tC('order_status')}
                </TableCell>
                <TableCell align="left" sx={{ minWidth: '160px' }}>
                  {tC('delivery_type')}
                </TableCell>
                <TableCell align="left" sx={{ minWidth: '160px' }}>
                  {tC('attachment')}
                </TableCell>
                {permissionToAdd && (
                  <TableCell align="center">{tC('action')}</TableCell>
                )}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {loading ? (
              <Loading type="table" tableColumns={8} />
            ) : (
              rentProdctsList &&
              rentProdctsList.data &&
              rentProdctsList.data.map((product: any, index: number) => (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={`product-${product._id}`}
                >
                  <TableCell component="td" scope="row" align="left">
                    <img
                      className="listImage"
                      src={
                        product.productImage
                          ? product.productImage
                          : Images.AUTH_BG_1
                      }
                      alt={AIT().productImg}
                    />
                  </TableCell>
                  <TableCell component="td" scope="row" align="left">
                    <p
                      className="link pointer"
                      onClick={() => {
                        if (product.isChildProduct) {
                          return navigate(
                            `/edit-${
                              product.shoppingMode ===
                              ORDER_PRODUCT_TYPES.service
                                ? 'service'
                                : 'product'
                            }/edit-variant/${product?.parentProductId}/${
                              product.productId
                            }`,
                          );
                        } else if (
                          product?.isPackage ||
                          product?.itemType == PRODUCT_TABS.package.value
                        ) {
                          navigate(`/edit-package/${product.productId}`);
                        } else {
                          return navigate(
                            `/edit-${
                              product.shoppingMode ===
                              ORDER_PRODUCT_TYPES.service
                                ? 'service'
                                : 'product'
                            }/${product.productId}`,
                          );
                        }
                      }}
                    >
                      {product.productName}
                    </p>
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    className="tr_input_110"
                  >
                    {/* {pollingStatus && <p>Processing....</p>} */}
                    {editedItem &&
                    editedItem.index === index &&
                    editedItem.mode === OrderEditOptionValues.edit_quantity ? (
                      <InputField
                        id="edit_qty"
                        label=""
                        type="number"
                        name="edit_qty"
                        placeholder=""
                        error={false}
                        helperText={''}
                        onChange={(e: any) => {
                          if (
                            e.target.value === '0' &&
                            e.target.value.startsWith('0')
                          ) {
                            return;
                          }
                          if (e.target.value.length <= 6) {
                            setQuantity(e.target.value);
                          }
                        }}
                        value={quantity}
                        onKeyDown={(e: any) => {
                          if (e.which === 8) {
                            return;
                          }
                          if (e.which < 48 || e.which > 57) {
                            e.preventDefault();
                          }
                          if (e.which === 38 || e.which === 40) {
                            e.preventDefault();
                          }
                        }}
                      />
                    ) : (
                      <p className="text_a_center w_100">{product.qty}</p>
                    )}
                  </TableCell>
                  <TableCell component="td" scope="row" align="left">
                    <div
                      className="flex g_5"
                      onClick={() => {
                        dispatch(
                          setDeliveryAddressModalStatus({
                            mode: ModalMode.edit,
                            itemDetails: product,
                          }),
                        );
                      }}
                    >
                      <span>
                        <IconButton sx={{ padding: '2px' }}>
                          <img src={Images.MAP_PIN} alt="" />
                        </IconButton>
                      </span>
                      <a className="m_0 cursor_pointer">
                        <p className="m_0 text_u">
                          {product?.deliveryAddress.address ||
                            product?.deliveryAddress.locationName}
                        </p>
                      </a>
                    </div>
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    sx={{ minWidth: '220px' }}
                  >
                    <p className="no_wrap">
                      {' '}
                      {dayjs(product?.startTime).format(
                        `MMM D, YYYY  ${
                          product?.shoppingMode === 'SERVICE' ||
                          product?.isStartTimeExist
                            ? 'LT'
                            : ''
                        }`,
                      ) || tC('notAvailable')}
                    </p>
                  </TableCell>

                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    sx={{ minWidth: '220px' }}
                  >
                    <p className="no_wrap">
                      {dayjs(product?.endTime).format(
                        `MMM D, YYYY  ${
                          product?.shoppingMode === 'SERVICE' ||
                          product?.isEndTimeExist
                            ? 'LT'
                            : ''
                        }`,
                      ) || tC('notAvailable')}
                    </p>
                  </TableCell>
                  <TableCell component="td" scope="row" align="center">
                    {(product?.shoppingMode === 'SERVICE' &&
                      product?.rateModel?.shiftLength) ||
                      tC('notAvailable')}
                  </TableCell>
                  <TableCell component="td" scope="row" align="left">
                    <p>{product?.shiftCoverage || tC('notAvailable')}</p>
                  </TableCell>
                  <TableCell component="td" scope="row" align="left">
                    {/* {product?.note?.length < 40 ? (
                      <p>{product?.note || 'N/A'}</p>
                    ) : (
                      <ToolTipComponent theme='light' title={product?.note}>
                        <p>{product?.note || 'N/A'}</p>
                      </ToolTipComponent>
                    )} */}
                    {product?.note ? (
                      <p
                        className="text_u cursor_pointer"
                        onClick={() =>
                          setNoteModel({
                            ...noteModel,
                            status: true,
                            notes: product?.note,
                          })
                        }
                      >
                        View
                      </p>
                    ) : (
                      tC('notAvailable')
                    )}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    sx={{ minWidth: '220px' }}
                  >
                    <p className="no_wrap">
                      {/* {' '}
                      {dayjs(product.updatedAt).format('MMMM D, YYYY | LT')} */}
                      {getTimeWithTimeZoneAbbreviation(product.updatedAt)}
                    </p>
                  </TableCell>
                  <TableCell component="td" scope="row" align="center">
                    {/* <p> */}
                    {product?.rateModel?.rentPrice
                      ? `$ ${formatPrice(product?.rateModel?.rentPrice)}`
                      : tC('notAvailable')}
                    {/* </p> */}
                  </TableCell>
                  <TableCell component="td" scope="row" align="center">
                    {/* <p> */}
                    {product.itemTotal
                      ? `$ ${formatPrice(product.itemTotal)}`
                      : tC('notAvailable')}
                    {/* </p> */}
                  </TableCell>

                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    sx={{ minWidth: '160px', label: { margin: 0 } }}
                  >
                    {pollingStatus &&
                    editedItem &&
                    editedItem.index === index ? (
                      <p className="tbl_text_center">Processing...</p>
                    ) : editedItem &&
                      editedItem.index === index &&
                      editedItem.mode === OrderEditOptionValues.edit_status ? (
                      <CustomSelect
                        id=""
                        name=""
                        value={selectStatus}
                        itemDisableLogic={(item) =>
                          statusDisable(product, item)
                        }
                        onChange={(e: any) => setSelectStatus(e.target.value)}
                        options={
                          ['SERVICE', 'RENT'].includes(
                            editedItem?.product?.shoppingMode,
                          )
                            ? ServiceStatusOptions
                            : RentstatusOptions
                        }
                      />
                    ) : (
                      <p className="tbl_text_center">
                        {ORDER_PRODUCT_STATUS[product.status]
                          ? ORDER_PRODUCT_STATUS[product.status]
                          : tC('notAvailable')}
                      </p>
                    )}
                  </TableCell>
                  <TableCell>
                    <p>
                      {product?.isRushEnable === 0
                        ? 'Standard Delivery'
                        : 'Expedited Delivery'}
                    </p>
                  </TableCell>
                  <TableCell
                    align={
                      product?.attachmentUrls?.length > 0 ? 'left' : 'center'
                    }
                  >
                    {/* product?.attachmentUrls?.length > 0  */}
                    {product?.attachmentUrls?.length > 0 ? (
                      <a
                        href="javascript:void(0)"
                        onClick={() => {
                          handleDownload(
                            product?.attachmentUrls,
                            product?.productName,
                            index,
                          );
                        }}
                      >
                        Attachments
                      </a>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  {editedItem &&
                  editedItem.index === index &&
                  !pollingStatus &&
                  [
                    OrderEditOptionValues.edit_quantity,
                    OrderEditOptionValues.edit_status,
                  ].includes(editedItem?.mode) &&
                  permissionToAdd ? (
                    <TableCell
                      component="td"
                      scope="row"
                      align="center"
                      sx={{
                        width: '115px !important',
                        minWidth: '115px !important',
                        maxWidth: '115px !important',
                        textAlign: 'center',
                      }}
                    >
                      <div className="tableActions">
                        <IconButton
                          id="check-button"
                          onClick={handleEditQuantityAndStatus}
                          className="btn_check"
                          disabled={
                            editedItem.mode === 'edit_quantity'
                              ? !quantity || quantity == editedItem.product?.qty
                              : StatusMap.get(selectStatus) ===
                                  editedItem?.product?.status || pollingStatus
                              ? true
                              : false
                          }
                        >
                          <img src={Images.CHECL_CTA} alt="" />
                        </IconButton>
                        <IconButton
                          id="close-button"
                          className="btn_close"
                          onClick={() => {
                            setEditedItem({
                              index,
                              product,
                              mode: null,
                            });
                            setSelectStatus('');
                          }}
                        >
                          <img src={Images.CLOSE_CTA} alt="" />
                        </IconButton>
                      </div>
                    </TableCell>
                  ) : (
                    permissionToAdd &&
                    !pollingStatus && (
                      <TableCell component="td" scope="row" align="center">
                        <IconButton
                          id="more-button"
                          aria-controls={open ? 'menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={(e) => handleClick(e, product, index)}
                          disabled={
                            ['REJECTED', 'CANCELLED'].includes(product?.status)
                            // (editedItem?.product?.shoppingMode &&
                            //   editedItem?.product?.status === 'ENDED')
                          }
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          {['PLACED'].includes(editedItem?.product.status)
                            ? Object.values(EditRentOptions).map(
                                (option, i) => (
                                  <MenuItem
                                    key={`option-${option.value}`}
                                    value={option.value}
                                    onClick={() =>
                                      handleMenuSelect(option.value)
                                    }
                                  >
                                    {option.label}
                                  </MenuItem>
                                ),
                              )
                            : [`COMPLETED`].includes(editedItem?.product.status)
                            ? Object.values(EditRentOptionsWithEditAndStatus)
                                // ?.filter(
                                //   (item) =>
                                //     !(
                                //       item.label === 'View Worksheet' &&
                                //       editedItem?.product?.shoppingMode ===
                                //         'RENT'
                                //     ),
                                // )
                                ?.map((option, i) => (
                                  <MenuItem
                                    key={`option-${option.value}`}
                                    value={option.value}
                                    onClick={() =>
                                      handleMenuSelect(option.value)
                                    }
                                    style={
                                      !option?.value ? { display: 'none' } : {}
                                    }
                                    disabled={i === 1 || i === 2}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))
                            : Object.values(
                                EditRentOptionsWithEditAndStatus,
                              )?.map((option: any, i: number) => (
                                <MenuItem
                                  key={`option-${option.value}`}
                                  value={option.value}
                                  onClick={() =>
                                    handleMenuSelect(option?.value)
                                  }
                                  style={
                                    !option?.value ? { display: 'none' } : {}
                                  }
                                  disabled={
                                    i === 1 &&
                                    !['ACCEPTED'].includes(
                                      editedItem?.product.status,
                                    ) &&
                                    true
                                  }
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                        </Menu>
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        {!loading &&
        Boolean(!rentProdctsList.data || rentProdctsList.data.length === 0) ? (
          <NoData
            heading={t(`noServiceAddedTitle`)}
            title={t('noServiceAddedDes')}
            image=""
          />
        ) : (
          <></>
        )}

        {showPagination ? (
          <TablePagination
            listData={rentProdctsList}
            setPageChange={(val: number) => handlePageChange(val)}
          />
        ) : (
          <></>
        )}
      </TableContainer>

      <RejectRentDailog
        open={openReject}
        handleClose={handleRejectClose}
        orderid={editedItem?.product?._id}
      />

      {/* Note's Dialog  */}
      <Dialog
        open={noteModel.status}
        onClose={handleNoteModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="dialogWrapper warning orderAddress">
          <div className="dialogHeader flex sps_between">
            <h4 className="small">Notes</h4>
            <IconButton onClick={handleNoteModel}>
              <img src={Images.CLOSE} alt={tC('close')} />
            </IconButton>
          </div>
          <div className="doalogBody small">
            <h1>{noteModel.notes}</h1>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
