import { Box, FormLabel, Grid, Tab, Tabs } from '@mui/material';
import CustomButton from '../../Components/CustomButton';
import InputField from '../../Components/Input';
import PageHeading from '../../Components/PageHaeding';
import SectionContainer from '../../Components/SectionContainer';
import { useTranslation } from 'react-i18next';
import Images from '../../Utils/images';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../helpers/contants';
import React, { useEffect, useMemo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import BuyTable from './components/BuyTable';
import RentTable from './components/RentTable';
import {
  getAndOrderDetails,
  getOrderProductsList,
  setCancelModalData,
  setOrderProductList,
  updateAddToOrderType,
} from '../../redux/order.slice';
import { useAppSelector } from '../../redux/store';
import Loading from '../../Components/Loading';
import {
  ORDER_PRODUCT_TYPES,
  ORDER_STATUS,
  OrderStatusLabel,
  PRODUCT_TYPE_BOTH,
  USER_ROLES,
} from '../../Utils/constantData';
import { getTimeWithTimeZoneAbbreviation } from '../../Utils/helperFns';
import { useDispatch } from 'react-redux';
import { AddToOrder, Chat } from '../../types';
import { setFilters, setSelected } from '../../redux/products.slice';
import { updatePaginationPage } from '../../redux/common.slice';
import { useSocket } from '../Chats/SocketContext';
import { postApiCall } from '../../api/methods';
import endPoints from '../../api/endpoint';
import {
  AddProductsAndServiceList,
  ProductsAndServiceIds,
  addChats,
  selectQuote,
  setNewMessages,
} from '../../redux/chat.slice';
import DisplayTagsInput from '../../Components/DisplayTagsInput';
import ViewInTags from '../../Components/ViewInTags';

export const defaultProductListPayload = 'pageNo=1&limit=5';

export default function OrderDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t: tC } = useTranslation('translation', {
    keyPrefix: 'Common',
  });
  const { t } = useTranslation('translation', {
    keyPrefix: 'orders.detail',
  });
  const { orderDetails, orderDetailsLoader } = useAppSelector(
    (state) => state.order,
  );
  const { permissionToAdd, userData } = useAppSelector((state) => state.auth);
  const { chatMap } = useAppSelector((state) => state.chat);
  const socketClient = useSocket();

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 2, px: 0, pb: 0 }}>
            <div className="tabContainer">{children}</div>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('active');

  const [value, setValue] = React.useState(activeTab === 'sell' ? 1 : 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    updatePaginationPage(1);
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(selectQuote(null));
    if (id) {
      getAndOrderDetails(id);
      getOrderProductsList(
        id,
        ORDER_PRODUCT_TYPES.buy,
        defaultProductListPayload,
      );
      getOrderProductsList(
        id,
        ORDER_PRODUCT_TYPES.rent,
        defaultProductListPayload,
      );
    }
    if (activeTab === 'sell') {
      setSearchParams((prevSearchParams) => {
        const params = new URLSearchParams(prevSearchParams);
        params.delete('active');
        return params.toString();
      });
    }
    dispatch(setSelected([]));
    dispatch(ProductsAndServiceIds([]));
    dispatch(AddProductsAndServiceList([]));
    return () => {
      dispatch(setOrderProductList(null));
      updatePaginationPage(1);
    };
  }, []);
  // user id from this
  const userChatId = useMemo(() => {
    const chats = Object.values(chatMap || {});
    if (orderDetails && chats.length > 0) {
      return (
        chats.find(
          (chat: Chat) => chat.contactDetails?._id === orderDetails.userId,
        )?._id || null
      );
    }
    return null;
  }, [chatMap, orderDetails]);

  const handleChatNow = () => {
    if (userChatId) {
      // If chat exists, activate it and navigate to the chat screen
      socketClient?.activeChat({ chatId: userChatId });
      navigate(ROUTES.CHATS);
      return;
    }

    if (!orderDetails) {
      // If no order details are available, do nothing
      return;
    }

    // Create a new chat if none exists
    postApiCall(
      endPoints.chat.createChat,
      {
        contactId: orderDetails.userId,
        contactType: 'user',
        businessChatType: 'user_vendor_chat',
        type: 'ONE_TO_ONE',
      },
      ({ data }: { data: any }) => {
        // Construct a new chat object
        const newChat: Chat = {
          _id: data.chatId,
          members: [orderDetails.userId, userData._id],
          type: 'ONE_TO_ONE',
          businessChatType: 'user_vendor_chat',
          lastMessageDetail: {},
          lastMsgCreated: new Date().getTime(),
          contactDetails: {
            _id: orderDetails.userId,
            profilePicture: data.profileImage,
            firstName: data.firstName,
            lastName: data.lastName,
            fullName: `${data.firstName} ${data.lastName || ''}`,
          },
          noOfUnreadMessage: 0,
          userOnlineStatus: null,
          isNewChat: true,
        };

        // Construct an empty message object for the new chat
        const newMessage = {
          chatId: data.chatId,
          messages: [],
        };

        // Update Redux store with the new chat and messages
        const newChats = [...Object.values(chatMap), newChat];
        dispatch(addChats(newChats));
        dispatch(setNewMessages(newMessage));

        // Activate the new chat and navigate to the chat screen
        socketClient?.activeChat({ chatId: data.chatId, newChat: true });
        navigate(ROUTES.CHATS);
      },
      (error: any) => {
        console.error('Error creating chat:', error);
      },
    );
  };
  const chatPermission =
    userData.role === USER_ROLES.VENDOR ||
    (userData.role === USER_ROLES.CO_VENDOR &&
      Object.keys(userData.permissions).includes('Chats'));

  return (
    <div className="pageBody">
      <PageHeading
        pageTitle={t('title')}
        pageName={t('page_name')}
        navigateUrl={ROUTES.ORDERS}
        buttonChildren={
          orderDetailsLoader || orderDetails === null ? (
            <></>
          ) : (
            <div className="orderDetailTop">
              {permissionToAdd && (
                <div className="topRow">
                  <CustomButton
                    size="large"
                    variant="contained"
                    text={t('view_transactions')}
                    showIcon={false}
                    disabled={true}
                    width="auto"
                    type="submit"
                    id="view_transactions"
                    color="secondary"
                    className="btnViewTransactions"
                  />
                  <CustomButton
                    size="large"
                    variant="contained"
                    text={t('cancel_order')}
                    showIcon={false}
                    width="auto"
                    type="submit"
                    id="cancel_order"
                    disabled={
                      ORDER_STATUS[1].value !== orderDetails.vendorOrderStatus
                    }
                    onClick={() => {
                      dispatch(
                        setCancelModalData({
                          isOrderCancle: true,
                          orderDetails: orderDetails,
                        }),
                      );
                    }}
                  />
                </div>
              )}

              <div className="bottomRow">
                <div
                  className={`status ${
                    orderDetails.vendorOrderStatus &&
                    orderDetails.vendorOrderStatus.toLowerCase()
                  }`}
                >
                  <span></span>
                  {OrderStatusLabel[orderDetails.vendorOrderStatus]}
                </div>
                {chatPermission && !orderDetails.isVendorCartOrder && (
                  <CustomButton
                    size="large"
                    variant="outlined"
                    text={tC('chat_now')}
                    showIcon={true}
                    width="auto"
                    type="submit"
                    disabled={orderDetails.isVendorCartOrder}
                    id="chat_now"
                    iconPosition="start"
                    icon={Images.CHAT}
                    onClick={handleChatNow}
                    className="btnChatNow"
                  />
                )}
              </div>
            </div>
          )
        }
      />
      {orderDetailsLoader ? (
        <Loading type="div" height="full" />
      ) : orderDetails === null ? (
        <p>{t('notFound')}</p>
      ) : (
        <div className={`formScroller order_detail`}>
          <SectionContainer
            heading={t('order_summary')}
            children={
              <>
                <div className="formContainer">
                  <Grid container spacing={2}>
                    {orderDetails?.productionName && (
                      <Grid item md={4} xs={12}>
                        <InputField
                          requiredField
                          id="production_name"
                          label={t('production_name')}
                          placeholder={t('production_name_placeholder')}
                          name="production_name"
                          disabled
                          value={
                            orderDetails?.productionName || tC('notAvailable')
                          }
                        />
                      </Grid>
                    )}

                    {/* new text fields as per figma */}
                    <Grid item md={4} xs={12}>
                      <InputField
                        requiredField
                        id="order_id"
                        label={t('order_id')}
                        name="order_id"
                        disabled
                        value={orderDetails.vendorOrderId}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <InputField
                        id="email"
                        label={t('order_date')}
                        name="order_date"
                        value={getTimeWithTimeZoneAbbreviation(
                          orderDetails.createdAt,
                        )}
                        disabled
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <InputField
                        id="website"
                        label={t('order_by')}
                        name="order_by"
                        error={false}
                        value={orderDetails.userName ?? tC('notAvailable')}
                        disabled
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <InputField
                        id="establishment"
                        label={t('email_address')}
                        name="email_address"
                        disabled
                        value={
                          orderDetails.isVendorCartOrder
                            ? orderDetails?.orderEmail
                            : orderDetails.email
                        }
                      />
                    </Grid>

                    {orderDetails?.isVendorCartOrder &&
                    orderDetails?.orderPhoneNo ? (
                      <Grid item md={4} xs={12}>
                        <InputField
                          id="establishment"
                          label={t('mobile_number')}
                          name="mobile_number"
                          disabled
                          value={`${orderDetails.orderPhoneNo || ''}`}
                        />
                      </Grid>
                    ) : (
                      orderDetails.countryCode && (
                        <Grid item md={4} xs={12}>
                          <InputField
                            id="establishment"
                            label={t('mobile_number')}
                            name="mobile_number"
                            disabled
                            value={`${orderDetails.countryCode} ${
                              orderDetails.phoneNo || ''
                            }`}
                          />
                        </Grid>
                      )
                    )}

                    {orderDetails?.isVendorCartOrder &&
                    orderDetails?.orderSetName ? (
                      <Grid item md={4} xs={12}>
                        <DisplayTagsInput
                          data={[{ setName: orderDetails?.orderSetName }]}
                          labelText={t('set_name')}
                          placeHolderText={t('select_set_name')}
                        />
                      </Grid>
                    ) : (
                      orderDetails.sets && (
                        <Grid item md={4} xs={12}>
                          <DisplayTagsInput
                            data={orderDetails.sets}
                            labelText={t('set_name')}
                            placeHolderText={t('select_set_name')}
                          />
                        </Grid>
                      )
                    )}

                    {!orderDetails?.isVendorCartOrder && (
                      <Grid item md={8} xs={12}>
                        <InputField
                          id="establishment"
                          label={t('desc')}
                          name="desc"
                          disabled
                          value={`${orderDetails.description || '-'}`}
                        />
                      </Grid>
                    )}
                  </Grid>
                </div>

                <div className="formContainer">
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                      <FormLabel className="labelHeading">
                        {t('production_name')}
                      </FormLabel>
                      <p className="labelText">
                        {orderDetails?.productionName || tC('notAvailable')}
                      </p>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormLabel className="labelHeading">
                        {t('order_id')}
                      </FormLabel>
                      <p className="labelText">{orderDetails.vendorOrderId}</p>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormLabel className="labelHeading">
                        {t('order_date')}
                      </FormLabel>
                      <p className="labelText">
                        {getTimeWithTimeZoneAbbreviation(
                          // )} //   'MMMM D, YYYY | LT	' // {dayjs(orderDetails.createdAt).format(
                          orderDetails.createdAt,
                        )}
                      </p>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormLabel className="labelHeading">
                        {t('order_by')}
                      </FormLabel>
                      <p className="labelText">
                        {orderDetails.userName ?? tC('notAvailable')}
                      </p>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormLabel className="labelHeading">
                        {t('email_address')}
                      </FormLabel>
                      <p className="labelText">
                        {orderDetails.isVendorCartOrder
                          ? orderDetails?.orderEmail
                          : orderDetails.email}
                      </p>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormLabel className="labelHeading">
                        {t('mobile_number')}
                      </FormLabel>
                      <p className="labelText">{`${
                        orderDetails.orderPhoneNo || ''
                      }`}</p>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormLabel className="labelHeading">
                        {t('set_name')}
                      </FormLabel>
                      <p className="labelText">
                        <ViewInTags
                          data={[
                            'INT. Dan’s House',
                            'Mansion',
                            'INT. Dan’s House',
                            'INT. Mansion',
                          ]}
                          limit={2}
                        />
                      </p>
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <FormLabel className="labelHeading">
                        {t('desc')}
                      </FormLabel>
                      <p className="labelText">{`${
                        orderDetails.description || '-'
                      }`}</p>
                    </Grid>
                  </Grid>
                </div>
              </>
            }
          />

          <SectionContainer
            heading={t('products_services_overview')}
            children={
              <div className="formContainer">
                <div className="tabs">
                  <Box sx={{ width: '100%' }}>
                    <Box sx={{ width: '100%' }} padding={0}>
                      <Box
                        sx={{ borderBottom: 0, borderColor: 'divider' }}
                        className="tabsContainer"
                      >
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="Tabs"
                        >
                          <Tab
                            label={tC('rent')}
                            {...a11yProps(1)}
                            // disabled={true}
                            value={0}
                          />
                          <Tab label={tC('sell')} {...a11yProps(0)} value={1} />
                        </Tabs>
                        {permissionToAdd && (
                          <div className="flex end">
                            <CustomButton
                              size="large"
                              variant="contained"
                              text={t(
                                value === 1
                                  ? 'add_products'
                                  : 'add_product_services',
                              )}
                              showIcon={true}
                              width="auto"
                              type="submit"
                              id="download_invoice"
                              iconPosition="start"
                              iconType="svg"
                              disabled={
                                ORDER_STATUS[1].value !==
                                orderDetails.vendorOrderStatus
                              }
                              icon={<AddIcon />}
                              onClick={() => {
                                navigate(ROUTES.ORDERS_PRODUCTS);
                                dispatch(
                                  updateAddToOrderType(
                                    value === 1
                                      ? AddToOrder.purchase
                                      : AddToOrder.rent,
                                  ),
                                );
                                value === 1 &&
                                  dispatch(
                                    setFilters({
                                      type: PRODUCT_TYPE_BOTH.allProducts,
                                    }),
                                  );
                              }}
                            />
                          </div>
                        )}
                      </Box>
                      <CustomTabPanel value={value} index={1}>
                        <BuyTable />
                      </CustomTabPanel>
                      <CustomTabPanel value={value} index={0}>
                        <RentTable />
                      </CustomTabPanel>
                    </Box>
                  </Box>
                </div>
              </div>
            }
          />
        </div>
      )}
    </div>
  );
}
