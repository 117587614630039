import {
  IconButton,
  Link,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Images from '../../../Utils/images';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TablePagination from '../../../Components/Pagination';
import React, { useEffect, useState } from 'react';
import InputField from '../../../Components/Input';
import CustomSelect from '../../../Components/CustomSelect';
import EditDeliveryAddress from './EditDeliveryAddress';
import { useAppSelector } from '../../../redux/store';
import dayjs from 'dayjs';
import { AIT } from '../../../Utils/Localization';
import {
  ITEM_TYPE,
  ORDER_PRODUCT_STATUS,
  ORDER_PRODUCT_TYPES,
} from '../../../Utils/constantData';
import Loading from '../../../Components/Loading';
import {
  EditOptions,
  EditedProductOrder,
  ModalMode,
  OrderEditOptionValues,
} from '../../../types';
import {
  getOrderProductsList,
  setCancelModalData,
  setEditedItemStore,
  updateQuantity,
  updateStatus,
  setDeliveryAddressModalStatus,
  getAndOrderDetails,
} from '../../../redux/order.slice';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updatePaginationPage } from '../../../redux/common.slice';
import NoData from '../../../Components/Nodata';
import {
  downloadZipFromS3,
  formatPrice,
  getTimeWithTimeZoneAbbreviation,
} from '../../../Utils/helperFns';

const StatusMap = new Map();

StatusMap.set('In Transit', 'INTRANSIT');
StatusMap.set('Shipped', 'SHIPPED');
StatusMap.set('Delivered', 'DELIVERED');

export default function BuyTable() {
  const { id } = useParams();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [disableDownload, setDisableDownload] = useState<any>({
    status: false,
    index: '',
  });

  const [showEditAddressModal, setShowEditAddressModal] =
    useState<boolean>(false);
  const { productOrderList } = useAppSelector((state) => state.order);
  const [selectedAddress, setSelectedAddress] = useState({
    _id: '',
    deliveryAddress: {
      productionId: '',
    },
  });
  const { permissionToAdd } = useAppSelector((state) => state.auth);

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  const { loading } = useAppSelector((state) => state.common);
  const [editedItem, setEditedItem] = useState<EditedProductOrder | null | any>(
    null,
  );
  const [quantity, setQuantity] = useState<any>(0);
  const [selectStatus, setSelectStatus] = useState('');
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    product: any,
    index: number,
  ) => {
    setEditedItem({
      index,
      product,
      mode: null,
    });
    setSelectStatus(ORDER_PRODUCT_STATUS[product.status]);
    setQuantity(product.qty);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedAddress({
      _id: '',
      deliveryAddress: {
        productionId: '',
      },
    });
  };

  const { t: tC } = useTranslation('translation', {
    keyPrefix: 'Common',
  });

  const { t } = useTranslation('translation', {
    keyPrefix: 'orders.detail',
  });

  // useEffect(() => {
  //   if (
  //     selectedAddress &&
  //     selectedAddress.deliveryAddress &&
  //     selectedAddress.deliveryAddress.productionId
  //   ) {
  //     // debugger
  //     getSetNameList(selectedAddress?.deliveryAddress?.productionId)
  //   }
  // }, [selectedAddress.deliveryAddress.productionId])
  const statusOptions = [
    `${tC('order_placed')}`,
    `${tC('shipped')}`,
    `${tC('in_transit')}`,
    `${tC('delivered')}`,
  ];

  const handlePageChange = (page: number) => {
    updatePaginationPage(page);

    id &&
      getOrderProductsList(
        id,
        ORDER_PRODUCT_TYPES.buy,
        `pageNo=${page}&limit=5`,
      );
  };

  const handleMenuSelect = (value: OrderEditOptionValues) => {
    editedItem &&
      setEditedItem((prev: any) => {
        if (prev) {
          prev['mode'] = value;
        }
        return prev;
      });

    if (value === OrderEditOptionValues.cancel_order) {
      dispatch(setCancelModalData({ isOrderCancle: false }));
      dispatch(setEditedItemStore(editedItem));
    }

    setAnchorEl(null);
  };

  const handleEditQuantityAndStatus = () => {
    if (editedItem?.mode === OrderEditOptionValues.edit_quantity) {
      updateQuantity(
        {
          // vendorOrderId: editedItem?.product?.vendorOrderId,
          // productId: editedItem?.product?.productId,
          orderId: editedItem?.product?._id,
          quantity: +quantity,
        },
        (data: any) => {
          if (id && data) {
            getOrderProductsList(
              id,
              ORDER_PRODUCT_TYPES.buy,
              `&pageNo=${1}&limit=5`,
            );
            setEditedItem(null);
          }
        },
      );
    } else if (editedItem?.mode === OrderEditOptionValues.edit_status) {
      const payload = {
        orderId: editedItem?.product?._id,
        status: StatusMap.get(selectStatus),
      };

      updateStatus(payload, (data: any) => {
        if (id && data) {
          getAndOrderDetails(id);
          getOrderProductsList(
            id,
            ORDER_PRODUCT_TYPES.buy,
            `&pageNo=${1}&limit=5`,
          );
          setEditedItem(null);
        }
      });
    }
  };

  const statusDisable = (product: any, item: string) => {
    const allowedStatuses: any = {
      [tC('order_placed')]: [
        tC('order_placed'),
        tC('in_transit'),
        tC('delivered'),
      ],
      [tC('shipped')]: [tC('shipped'), tC('order_placed'), tC('delivered')],
      [tC('in_transit')]: [
        tC('in_transit'),
        tC('shipped'),
        tC('order_placed'),
        ...(editedItem?.product?.paymentStatus === 'UNPAID'
          ? [tC('delivered')]
          : []),
      ],
    };

    return allowedStatuses[ORDER_PRODUCT_STATUS[product.status]]?.includes(
      item,
    );
  };

  const showPagination = Boolean(
    productOrderList &&
      !loading &&
      productOrderList.hasOwnProperty('totalPage') &&
      productOrderList.totalPage > 1 &&
      productOrderList.data &&
      productOrderList.data.length > 0,
  );

  const handleDownload = (attachments: any, name: string) => {
    // let a = [
    //   'https://app-development.s3.amazonaws.com/ReelProject/1725004166779_croppedImage.jpg',
    //   'https://app-development.s3.amazonaws.com/ReelProject/1724401606781_big_buck_bunny_720p_1mb.mp4',
    // ];
    let urls = attachments?.map((item: any) => item?.url);

    if (urls.length > 0) {
      downloadZipFromS3(urls, name);
    }
  };

  return (
    <div className="reelTableContainer">
      <TableContainer
        component={Paper}
        elevation={0}
        className={`reelTable w__140 ${
          permissionToAdd ? 'sticky_column' : ''
        } `}
      >
        <Table sx={{ minWidth: 650 }} aria-label="table" stickyHeader>
          {Boolean(
            !productOrderList?.data || productOrderList?.data?.length > 0,
          ) && (
            <TableHead>
              <TableRow>
                <TableCell>{tC('image')}</TableCell>
                <TableCell align="left">{t('product_name')}</TableCell>
                <TableCell align="center" sx={{ maxWidth: '110px' }}>
                  {tC('qty')}
                </TableCell>
                <TableCell align="center">{tC('unit_price')}</TableCell>
                <TableCell align="center">{tC('sub_total')}</TableCell>
                <TableCell align="left" sx={{ minWidth: '260px' }}>
                  {t('order_date')}
                </TableCell>
                <TableCell align="left">{tC('delivery_address')}</TableCell>
                <TableCell align="left" sx={{ minWidth: '160px' }}>
                  {tC('delivery_status')}
                </TableCell>
                <TableCell align="left">{tC('delivery_type')}</TableCell>
                <TableCell align="left" sx={{ minWidth: '160px' }}>
                  {tC('attachment')}
                </TableCell>

                <TableCell align="left">{tC('delivery_date')}</TableCell>
                {permissionToAdd && (
                  <TableCell align="center">{tC('action')}</TableCell>
                )}
              </TableRow>
            </TableHead>
          )}

          <TableBody>
            {loading ? (
              <Loading type="table" tableColumns={8} />
            ) : (
              productOrderList &&
              productOrderList.data &&
              productOrderList.data.map((product: any, index: number) => (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={`product-${product._id}`}
                >
                  <TableCell component="td" scope="row" align="left">
                    <img
                      className="listImage"
                      src={
                        product.productImage
                          ? product.productImage
                          : Images.AUTH_BG_1
                      }
                      alt={AIT().productImg}
                    />
                  </TableCell>
                  <TableCell component="td" scope="row" align="left">
                    <p
                      className="link pointer"
                      onClick={() => {
                        if (product?.isChildProduct) {
                          navigate(
                            `/edit-product/edit-variant/${product?.parentProductId}/${product.productId}`,
                          );
                        } else if (product?.itemType === ITEM_TYPE.PACKAGE) {
                          navigate(`/edit-package/${product.productId}`);
                        } else {
                          navigate(`/edit-product/${product.productId}`);
                        }
                      }}
                    >
                      {product.productName}
                    </p>
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                   className='tr_input_110'
                  >
                    {editedItem &&
                    editedItem.index === index &&
                    editedItem.mode === OrderEditOptionValues.edit_quantity ? (
                      <InputField
                        id="edit_qty"
                        label=""
                        type="number"
                        name="edit_qty"
                        placeholder=""
                        error={false}
                        helperText={''}
                        onChange={(e) => {
                          if (
                            e.target.value === '0' &&
                            e.target.value.startsWith('0')
                          ) {
                            return;
                          }
                          if (e.target.value.length <= 6) {
                            setQuantity(e.target.value);
                          }
                        }}
                        value={quantity}
                        onKeyDown={(e: any) => {
                          if (e.which === 8) {
                            return;
                          }
                          if (e.which < 48 || e.which > 57) {
                            e.preventDefault();
                          }
                          if (e.which === 38 || e.which === 40) {
                            e.preventDefault();
                          }
                        }}
                      />
                    ) : (
                      <p
                        className='dsp_block'
                      >
                        {product.qty}
                      </p>
                    )}
                  </TableCell>
                  <TableCell component="td" scope="row" align="center">
                    {product?.price
                      ? `$ ${formatPrice(product?.price)}`
                      : tC('notAvailable')}
                  </TableCell>
                  <TableCell component="td" scope="row" align="center">
                    {' '}
                    {product.itemTotal
                      ? `$ ${formatPrice(product.itemTotal)}`
                      : tC('notAvailable')}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="left"
                    sx={{ minWidth: '260px' }}
                  >
                    {/* <p>
                      {' '}
                      {dayjs(product.updatedAt).format('MMMM D, YYYY | LT')}
                    </p> */}
                    <p>
                      {/* {product.createdAt == product.updatedAt
                        ? t('table.created')
                        : t('table.updated')}{' '} */}
                      {getTimeWithTimeZoneAbbreviation(product.updatedAt)}
                    </p>
                  </TableCell>
                  <TableCell component="td" scope="row" align="left">
                    <div
                      className="flex g_5"
                      onClick={() => {
                        dispatch(
                          setDeliveryAddressModalStatus({
                            mode: ModalMode.edit,
                            itemDetails: product,
                          }),
                        );
                      }}
                    >
                      <span>
                        <IconButton sx={{ padding: '2px' }}>
                          <img src={Images.MAP_PIN} alt="" />
                        </IconButton>
                      </span>
                      <a className='m_0 cursor_pointer'>
                        <p className='m_0 text_u'>
                          {product?.deliveryAddress.address ||
                            product?.deliveryAddress.locationName}
                        </p>
                      </a>
                      {/* <p>{product.deliveryAddress.address}</p> */}
                    </div>
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="left"
                    sx={{ minWidth: '160px', label: { margin: 0 } }}
                  >
                    {editedItem &&
                    editedItem.index === index &&
                    editedItem.mode === OrderEditOptionValues.edit_status ? (
                      <CustomSelect
                        id=""
                        name=""
                        value={selectStatus}
                        itemDisableLogic={
                          (item) => statusDisable(product, item)
                          // item === `${tC('in_transit')}` ? true : false
                        }
                        onChange={(e: any) => setSelectStatus(e.target.value)}
                        options={statusOptions}
                      />
                    ) : (
                      <p>
                        {ORDER_PRODUCT_STATUS[product.status]
                          ? ORDER_PRODUCT_STATUS[product.status]
                          : tC('notAvailable')}
                      </p>
                    )}
                  </TableCell>
                  <TableCell>
                    <p>
                      {product?.isRushEnable === 0
                        ? 'Standard Delivery'
                        : 'Expedited Delivery'}
                    </p>
                  </TableCell>
                  <TableCell
                    align={
                      product?.attachmentUrls?.length > 0 ? 'left' : 'center'
                    }
                  >
                    {/* product?.attachmentUrls?.length > 0  */}
                    {product?.attachmentUrls?.length > 0 ? (
                      <a
                        href="javascript:void(0)"
                        style={
                          disableDownload?.status &&
                          index === disableDownload.index
                            ? {
                                pointerEvents: 'none',
                                color: 'gray',
                                cursor: 'notAllowed',
                              }
                            : {}
                        }
                        onClick={() =>
                          handleDownload(
                            product?.attachmentUrls,
                            product?.productName,
                          )
                        }
                      >
                        Attachments
                      </a>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell>
                    {product.isRushEnable === 0 ? (
                      <p>
                        {dayjs(product.createdAt)
                          .add(product.eta, 'days')
                          .format('MMMM D, YYYY')}
                      </p>
                    ) : (
                      <p>
                        {dayjs(product.createdAt)
                          .add(product.rushEta, 'days')
                          .format('MMMM D, YYYY')}
                      </p>
                    )}
                  </TableCell>
                  {editedItem &&
                  editedItem.index === index &&
                  [
                    OrderEditOptionValues.edit_quantity,
                    OrderEditOptionValues.edit_status,
                  ].includes(editedItem?.mode) &&
                  permissionToAdd ? (
                    <TableCell
                      component="td"
                      scope="row"
                      align="right"
                      sx={{
                        width: '115px !important',
                        minWidth: '115px !important',
                        maxWidth: '115px !important',
                        textAlign: 'center',
                      }}
                    >
                      <div className="tableActions">
                        <IconButton
                          id="check-button"
                          onClick={handleEditQuantityAndStatus}
                          className="btn_check"
                          disabled={
                            editedItem.mode === 'edit_quantity'
                              ? !quantity || quantity == editedItem.product?.qty
                              : StatusMap.get(selectStatus) ===
                                editedItem?.product?.status
                              ? true
                              : false
                          }
                        >
                          <img src={Images.CHECL_CTA} alt="" />
                        </IconButton>
                        <IconButton
                          id="close-button"
                          className="btn_close"
                          onClick={() => {
                            setEditedItem({
                              index,
                              product,
                              mode: null,
                            });
                            setSelectStatus('');
                          }}
                        >
                          <img src={Images.CLOSE_CTA} alt="" />
                        </IconButton>
                      </div>
                    </TableCell>
                  ) : (
                    permissionToAdd && (
                      <TableCell component="td" scope="row" align="center">
                        <IconButton
                          id="more-button"
                          aria-controls={open ? 'menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={(e) => handleClick(e, product, index)}
                          disabled={[`DELIVERED`, 'CANCELLED'].includes(
                            product?.status,
                          )}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          {Object.values(EditOptions).map((option, i) => (
                            <MenuItem
                              key={`option-${option.value}`}
                              value={option.value}
                              onClick={() => handleMenuSelect(option.value)}
                              disabled={
                                (i === 0 &&
                                  editedItem?.product.status !== 'PLACED') ||
                                (i === 0 &&
                                  editedItem?.product.paymentStatus ===
                                    'PAID' &&
                                  true)
                              }
                              style={!option?.value ? { display: 'none' } : {}}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Menu>
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        {Boolean(
          !productOrderList?.data || productOrderList?.data?.length === 0,
        ) ? (
          <NoData
            heading={t(`noBuyProductAddedTitle`)}
            title={t('noBuyProductAddedDes')}
            image={''}
            // buttonText={noFoundLogic ? undefined : `${tC('addNew')} ${name}`}
            // handleClick={() =>
            // navigate(
            //   tabValue === PRODUCT_TABS.product.value
            //     ? ROUTES.ADD_PRODUCTS
            //     : ROUTES.ADD_SERVICES
            // )
            // }
          />
        ) : (
          <></>
        )}

        {showPagination ? (
          <TablePagination
            listData={productOrderList}
            setPageChange={(val: number) => handlePageChange(val)}
          />
        ) : (
          <></>
        )}
      </TableContainer>

      {/* {
  deliveryAddressModalData !== null ?

      <DeliveryAddressDialog
        open={showDeliveryAddressModal}
        handleClose={() => setShowDeliveryAddressModal(false)}
        openEditAddressModal={openEditAddressModal} // Pass the function to open edit address modal
        selectedAddress={selectedAddress}
      />} */}

      <EditDeliveryAddress
        open={showEditAddressModal}
        handleClose={() => setShowEditAddressModal(false)}
        openAddAnotherAddressModal={false}
        selectedAddress={selectedAddress}
      />
      {/* <CancelOrderDialog open/> */}
    </div>
  );
}
