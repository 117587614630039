import { Box, FormHelperText, FormLabel, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { Controller } from 'react-hook-form';
import { PriceRegex, onlyApphabetsRejex } from '../Utils/constantData';
import CustomPopover from './CustomPopover';
import { isValidDecimalKey } from '../Utils/helperFns';
interface Props {
  id: string;
  label?: string;
  placeholder?: string;
  name: string;
  type?: string;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  defaultValue?: string | number;
  variant?: 'outlined' | 'standard' | 'filled' | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  onBlur?:
    | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  value?: string;
  startAdornment?: React.ReactElement | null;
  endAdornment?: React.ReactElement | null;
  control?: null | any;
  tooltip?: boolean;
  popoverContent?: React.ReactElement | null;
  requiredField?: boolean | any;
  pattern?: any;
  clearErrors?: any;
  changeCallback?: any;
  charLimit?: number; ///Only for chart count
  maxVal?: number; //Maximum Value
  autoComplete?: string;
  restrictedKeys?: string[];
  percentage?: boolean;
  labelSecondaryText?: string;
  isOnlyAlphabets?: boolean;
  allowedKeys?: string[];
  customRegex?: RegExp;
  hintText?: string;
  trimValueFromEnd?: boolean;
  isPriceField?: boolean;
  highLighted?: boolean;
  setValue?: any;
  index?: any;
  isZeroAllowed?: boolean;
}

const InputField: React.FC<Props> = ({
  id,
  label,
  placeholder = '',
  name,
  type = 'text',
  helperText,
  error = false,
  disabled = false,
  defaultValue,
  variant = 'outlined',
  value,
  startAdornment = null,
  endAdornment = null,
  onChange,
  control,
  tooltip,
  popoverContent,
  onKeyUp,
  onKeyPress,
  onPaste,
  requiredField,
  pattern,
  clearErrors,
  onKeyDown,
  onBlur,
  changeCallback,
  charLimit,
  autoComplete = 'off',
  restrictedKeys = [],
  percentage = false,
  labelSecondaryText,
  maxVal,
  isOnlyAlphabets,
  allowedKeys = [],
  customRegex,
  hintText,
  trimValueFromEnd = false,
  isPriceField = true,
  highLighted,
  setValue,
  index,
  isZeroAllowed = false,
}) => {
  // console.log(defaultValue,"defaultValue",name)

  return (
    <Box className={`${highLighted ? 'highLighted' : ''}`}>
      <FormLabel className="formLabel">
        {label}{' '}
        <span className="secondaryLabel">
          {labelSecondaryText && labelSecondaryText}
        </span>{' '}
        {requiredField ? <sup>*</sup> : null}
        {tooltip ? (
          <>
            <CustomPopover
              id=""
              children={popoverContent}
              arrow
              showHelpIcon
              anchorOrigin_vertical="top"
              anchorOrigin_horizontal="center"
              transformOrigin_vertical={145}
              transformOrigin_horizonral={202}
            />
          </>
        ) : null}
      </FormLabel>
      {control ? (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <TextField
                id={id}
                autoComplete={autoComplete}
                size="small"
                name={name}
                type={type}
                helperText={helperText}
                variant={variant}
                error={error}
                placeholder={placeholder}
                value={field.value ?? ''}
                disabled={disabled}
                color="primary"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  // console.log(' on change e--->', e);
                  const { value } = e.target;
                  if (maxVal && +e.target.value > maxVal) {
                    e.preventDefault();
                    return;
                  }
                  changeCallback && changeCallback(e);

                  // Only Allow Alphabets Start********
                  if (isOnlyAlphabets && !onlyApphabetsRejex.test(value)) {
                    e.preventDefault();
                    return;
                  }
                  if (customRegex && !customRegex.test(value)) {
                    e.preventDefault();
                    return;
                  }
                  // Only Allow Alphabets End ********
                  if (charLimit && +value.length > charLimit) {
                    const truncatedValue = value.slice(0, charLimit);
                    e.target.value = truncatedValue;
                  }
                  if (type === 'number') {
                    if (e.target.validity.badInput) {
                      e.preventDefault();
                      return;
                    }

                    if (!value.match(PriceRegex) && isPriceField) {
                      e.preventDefault();
                      return;
                    }
                    if (
                      value === '0' &&
                      !name.toLocaleLowerCase().includes('discount') &&
                      !name.toLocaleLowerCase().includes('otp') &&
                      !isZeroAllowed
                    ) {
                      // console.log('6');

                      //Excluding discount because in discount value can be 0.09 to 99.99
                      e.preventDefault();
                      return;
                    }

                    if (percentage) {
                      let v = Math.round(+value);
                      if (v > 100) {
                        e.preventDefault();
                        return;
                      }
                    }
                  }
                  if (restrictedKeys.includes(e.target.value)) {
                    // console.log('restricted');
                    e.preventDefault();
                    return;
                  }
                  if (trimValueFromEnd) {
                    e.target.value = e.target.value.trimEnd();
                  }

                  onChange(e);

                  if (!requiredField && clearErrors && value === '') {
                    clearErrors(name);
                  }
                }}
                fullWidth
                InputProps={{
                  startAdornment,
                  autoComplete: autoComplete,
                  endAdornment: endAdornment,
                  onKeyPress: onKeyPress,
                  // tabIndex: 0,
                }}
                onKeyUp={onKeyUp ? onKeyUp : (e) => {}}
                onKeyDown={
                  onKeyDown
                    ? onKeyDown
                    : (e) => {
                        // Prevent default if the user tries to enter a space when text is selected
                        const isTextSelected =
                          e.target.selectionStart !== e.target.selectionEnd;

                        if (
                          (e.key == 'z' && e.metaKey) ||
                          (e.key == 'z' && e.ctrlKey)
                        ) {
                          return;
                        }

                        if (e.key === ' ' && isTextSelected) {
                          e.preventDefault();
                          return;
                        }

                        if (e.ctrlKey || e.shiftKey || e.altKey || e.metaKey) {
                          if (
                            e.key === '+' &&
                            allowedKeys.length > 0 &&
                            !allowedKeys.includes('+')
                          ) {
                            e.preventDefault();
                            return;
                          }
                          return;
                        }

                        let defaultAllowedKeys = [
                          'ArrowRight',
                          'ArrowLeft',
                          'Backspace',
                          'Delete',
                          'Tab',
                        ];

                        defaultAllowedKeys = [
                          ...defaultAllowedKeys,
                          ...allowedKeys,
                        ];
                        if (!defaultAllowedKeys.includes(e.key)) {
                          if (type === 'number') {
                            const numberInputRestrictKeys = [
                              'Escape',
                              'Enter',
                              'ArrowUp',
                              'ArrowDown',
                              'e',
                            ];
                            if (numberInputRestrictKeys.includes(e.key)) {
                              e.preventDefault();
                            } else if (
                              name.includes('cost') ||
                              name.includes('Fee') ||
                              name.includes('price') ||
                              name.includes('Price') ||
                              name.includes('Rate')
                            ) {
                              // console.log('in price field');

                              if (!isValidDecimalKey(e.key, e.target.value)) {
                                // console.log('one');
                                e.preventDefault();
                              }
                            } else if (!/^[0-9]$/.test(e.key) && e.key != '.') {
                              // console.log('2');
                              e.preventDefault();
                              return;
                            } else if (
                              charLimit &&
                              e.target?.value?.length > charLimit
                            ) {
                              // console.log('3');
                              e.preventDefault();
                            }
                          }

                          if (
                            charLimit &&
                            e.target?.value?.length > charLimit
                          ) {
                            e.preventDefault();
                          }

                          if (restrictedKeys.includes(e.key)) {
                            // console.log('4');
                            e.preventDefault();
                            return;
                          }

                          if (
                            (field.value == '' ||
                              field.value === undefined ||
                              field.value === '00') &&
                            e.key === '0' &&
                            (!name.toLocaleLowerCase().includes('discount') ||
                              field.value === '00') &&
                            (!isZeroAllowed || field.value === '00')
                          ) {
                            // console.log('5');
                            e.preventDefault();
                            return;
                          }

                          if (
                            (field.value == '' || field.value === undefined) &&
                            (e.key === '.' || e.key === ' ')
                          ) {
                            e.preventDefault();
                            return;
                          }

                          if (
                            e.key === ' ' &&
                            e.target.value.slice(-1) === ' '
                          ) {
                            e.preventDefault();
                            return;
                          }
                        }
                      }
                }
                onPaste={
                  onPaste
                    ? onPaste
                    : (e) => {
                        let pastedText = e.clipboardData.getData('text');

                        // Restrict specific keys if provided
                        if (restrictedKeys.length > 0) {
                          const regex = new RegExp(
                            `[${restrictedKeys.join('')}]`,
                            'g',
                          );
                          pastedText = pastedText.replace(regex, '');
                        }

                        // Get the current field value and selection range
                        const {
                          value: currentValue,
                          selectionStart,
                          selectionEnd,
                        } = e.target;

                        // Calculate the new value considering selection
                        const newVal =
                          currentValue.slice(0, selectionStart) +
                          pastedText +
                          currentValue.slice(selectionEnd);

                        // Apply validations
                        // const exceedsCharLimit =
                        //   charLimit && newVal.length > charLimit;
                        const invalidNumber =
                          type === 'number' &&
                          ((maxVal && +pastedText > maxVal) || +pastedText < 0);
                        const invalidRegex =
                          customRegex && !customRegex.test(newVal);
                        // if (exceedsCharLimit) {
                        //   // Calculate how many characters are allowed to be pasted
                        //   const allowedChars = charLimit - currentValue.length;
                        //   // Trim the pasted text to fit the remaining characters
                        //   const trimmedText = pastedText.slice(0, allowedChars);

                        //   // Construct the new value with the trimmed text
                        //   const newTrimmedVal =
                        //     currentValue.slice(0, selectionStart) +
                        //     trimmedText +
                        //     currentValue.slice(selectionEnd);

                        //   // Set the new value into the target element
                        //   e.target.value = newTrimmedVal;
                        // }
                        if (invalidNumber || invalidRegex) {
                          // Prevent the default paste action
                          e.preventDefault();
                          return;
                        }
                      }
                }
                onBlur={
                  onBlur
                    ? (e) => {
                        onBlur(e);
                        field.onBlur(e);
                      }
                    : field.onBlur
                }
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                onFocus={(e) =>
                  type == 'number' &&
                  e.target.addEventListener(
                    'wheel',
                    (e) => e.preventDefault(),
                    { passive: false },
                  )
                }
              />
            );
          }}
        />
      ) : (
        <TextField
          id={id}
          size="small"
          name={name}
          type={type}
          helperText={helperText}
          variant={variant}
          error={error}
          placeholder={placeholder}
          disabled={disabled}
          color="primary"
          onChange={onChange}
          defaultValue={defaultValue}
          value={value}
          fullWidth
          InputProps={{
            startAdornment,
            autoComplete: 'off',
            endAdornment: endAdornment,
          }}
          onKeyUp={onKeyUp}
          onKeyDown={(e) => {
            onKeyDown && onKeyDown(e);
            if (e.ctrlKey || e.shiftKey || e.altKey || e.metaKey) {
              return; // Combination key press, do nothing
            }
            let defaultAllowedKeys = [
              'ArrowRight',
              'ArrowLeft',
              'Backspace',
              'Delete',
              'Tab',
            ];

            defaultAllowedKeys = [...defaultAllowedKeys, ...allowedKeys];
            if (!defaultAllowedKeys.includes(e.key)) {
              if (type === 'number') {
                const numberInputRestrictKeys = [
                  'Escape',
                  'Enter',
                  'ArrowUp',
                  'ArrowDown',
                  'e',
                ];
                if (numberInputRestrictKeys.includes(e.key)) {
                  e.preventDefault();
                } else if (
                  name.includes('cost') ||
                  name.includes('Fee') ||
                  name.includes('price') ||
                  name.includes('Price') ||
                  name.includes('Rate')
                ) {
                  if (!isValidDecimalKey(e.key, e.target.value)) {
                    // console.log('one');
                    e.preventDefault();
                  }
                } else if (!/^[0-9]$/.test(e.key) && e.key != '.') {
                  // console.log('2');
                  e.preventDefault();
                  return;
                } else if (charLimit && e.target?.value?.length > charLimit) {
                  // console.log('3');
                  e.preventDefault();
                }
              }

              if (charLimit && e.target?.value?.length > charLimit) {
                e.preventDefault();
              }

              if (restrictedKeys.includes(e.key)) {
                // console.log('4');
                e.preventDefault();
                return;
              }

              if (
                (value == '' || value === undefined || value === '00') &&
                e.key === '0' &&
                (!name.toLocaleLowerCase().includes('discount') ||
                  value === '00')
              ) {
                // console.log('5');
                e.preventDefault();
                return;
              }

              if (
                (value == '' || value === undefined) &&
                (e.key === '.' || e.key === ' ')
              ) {
                e.preventDefault();
                return;
              }
            }
          }}
          onPaste={onPaste}
          onBlur={onBlur}
        />
      )}
      <FormHelperText className="selectHintText">{hintText}</FormHelperText>
    </Box>
  );
};

export default InputField;
