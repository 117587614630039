import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { AIT } from '../Utils/Localization';
import Images from '../Utils/images';

interface Props {
  id: string;
  label?: string;
  name: string;
  disabled?: boolean;
  checked?: boolean;
  indeterminate?: boolean;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  control?: null | any;
  helperText?: String;
  isError?: boolean;
  color?: 'primary' | 'secondary' | 'success' | 'warning' | 'dander' | 'dark';
  theme?: 'primary' | 'white' | 'dual';
}

const InputCheckbox: React.FC<Props> = (props) => {
  const {
    id,
    label = '',
    name,
    disabled = false,
    checked,
    indeterminate = false,
    onChange,
    value,
    control,
    isError,
    color,
    theme,
  } = props;
  return (
    <FormGroup>
      <FormControlLabel
        label={label}
        id={id}
        name={name}
        className={`checkbox ${isError ? 'checkError' : ''} ${
          color ? `check_${color}` : ''
        } ${color === 'dark' ? '' : 'check_dark'} ${checked ? 'checked' : ''} 
        ${disabled && 'check-disabled'} ${
          theme === 'dual' ? 'check_dual' : ''
        }`}
        control={
          control ? (
            <Controller
              control={control}
              name={name}
              render={({ field }) => (
                <>
                  {theme === 'white' ? (
                    <Checkbox
                      checked={Boolean(checked)}
                      disabled={disabled}
                      disableRipple
                      {...field}
                      onChange={onChange ? onChange : field.onChange}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          const target = event.target as HTMLElement;
                          // Simulate a click on the focused element when Enter is pressed
                          target.click();
                        }
                      }}
                      icon={
                        disabled ? (
                          <img
                            src={Images.UNCHECK_DISABLED}
                            alt={`${AIT().checkbox}`}
                          />
                        ) : (
                          <img
                            src={
                              isError
                                ? Images.CHECK_RED
                                : Images.CHECK_TICK_WHITE
                            }
                            alt={`${AIT().checkbox}`}
                          />
                        )
                      }
                      checkedIcon={
                        disabled ? (
                          <img
                            src={Images.TICK_SQUARE_DISABLED}
                            alt={`${AIT().checkbox}`}
                          />
                        ) : (
                          <img
                            src={Images.CHECK_TICK_CHECKED_WHITE}
                            alt={`${AIT().checkbox}`}
                          />
                        )
                      }
                    />
                  ) : (
                    <Checkbox
                      checked={Boolean(checked)}
                      disabled={disabled}
                      disableRipple
                      {...field}
                      onChange={onChange ? onChange : field.onChange}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          const target = event.target as HTMLElement;
                          // Simulate a click on the focused element when Enter is pressed
                          target.click();
                        }
                      }}
                      icon={
                        disabled ? (
                          <img
                            src={Images.UNCHECK_DISABLED}
                            alt={`${AIT().checkbox}`}
                          />
                        ) : (
                          <img
                            src={isError ? Images.CHECK_RED : Images.CHECK}
                            alt={`${AIT().checkbox}`}
                          />
                        )
                      }
                      checkedIcon={
                        disabled ? (
                          <img
                            src={Images.TICK_SQUARE_DISABLED}
                            alt={`${AIT().checkbox}`}
                          />
                        ) : (
                          <img
                            src={Images.CHECK_TICK}
                            alt={`${AIT().checkbox}`}
                          />
                        )
                      }
                    />
                  )}
                </>
              )}
            />
          ) : (
            <>
              {theme === 'white' ? (
                <Checkbox
                  checked={checked}
                  indeterminate={indeterminate}
                  disabled={disabled}
                  onChange={onChange}
                  disableRipple
                  value={value}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      const target = event.target as HTMLElement;
                      // Simulate a click on the focused element when Enter is pressed
                      target.click();
                    }
                  }}
                  icon={
                    disabled ? (
                      <img
                        src={Images.UNCHECK_DISABLED}
                        alt={`${AIT().checkbox}`}
                      />
                    ) : (
                      <img
                        src={
                          isError ? Images.CHECK_RED : Images.CHECK_TICK_WHITE
                        }
                        alt={`${AIT().checkbox}`}
                      />
                    )
                  }
                  checkedIcon={
                    disabled ? (
                      <img
                        src={Images.UNCHECK_DISABLED}
                        alt={`${AIT().checkbox}`}
                      />
                    ) : (
                      <img
                        src={Images.CHECK_TICK_CHECKED_WHITE}
                        alt={`${AIT().checkbox}`}
                      />
                    )
                  }
                />
              ) : (
                <Checkbox
                  checked={checked}
                  indeterminate={indeterminate}
                  disabled={disabled}
                  onChange={onChange}
                  disableRipple
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      const target = event.target as HTMLElement;
                      // Simulate a click on the focused element when Enter is pressed
                      target.click();
                    }
                  }}
                  value={value}
                  icon={
                    disabled ? (
                      <img
                        src={Images.UNCHECK_DISABLED}
                        alt={`${AIT().checkbox}`}
                      />
                    ) : (
                      <img
                        src={isError ? Images.CHECK_RED : Images.CHECK}
                        alt={`${AIT().checkbox}`}
                      />
                    )
                  }
                  checkedIcon={
                    disabled ? (
                      <img
                        src={Images.TICK_SQUARE_DISABLED}
                        alt={`${AIT().checkbox}`}
                      />
                    ) : (
                      <img src={Images.CHECK_TICK} alt={`${AIT().checkbox}`} />
                    )
                  }
                />
              )}
            </>
          )
        }
      ></FormControlLabel>
    </FormGroup>
  );
};

export default InputCheckbox;
