import store from './store';
import { createSlice } from '@reduxjs/toolkit';
import { notify } from '../Utils/toastify';
import endPoints from '../api/endpoint';
import { getApiCall } from '../api/methods';
import { ApiStatus, CommonState } from '../types';
import { startLoading, stopLoading } from './loaders.slice';
import { MediaUploadLoader } from '../types/loaderTypes';

const initialState: CommonState = {
  countryList: [],
  departmentList: [],
  categories: [],
  // state to block route
  blockNavigation: false,
  loading: false,
  menu: false,
  mobileChat: false,
  actionApiStatus: ApiStatus.idle,
  activeMenu: 'Dashboard',
  ///This page is  used for pagination across app
  page: 1,
  searchedLocations: [],
  presignedUrl: null,
  baseUrl: null,
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setData: (state, action) => {
      const {
        payload: { type, value, emptyCags },
      }: any = action;
      if (type === 'country') {
        state.countryList = value;
      } else if (type === 'department') {
        state.departmentList = value;
        !emptyCags && (state.categories = []);
      } else if (type === 'categories') {
        state.categories = value;
      }
    },
    setCommonDataLoader: (state, action) => {
      const { payload }: any = action;
      state.loading = payload;
    },
    setCommonMenu: (state, action) => {
      const { payload }: any = action;
      state.menu = payload;
    },
    viewChatMobile: (state, action) => {
      const { payload }: any = action;
      state.mobileChat = payload;
    },
    updateActionApiStatus: (state, action) => {
      const { payload }: any = action;
      state.actionApiStatus = payload;
    },
    updateActiveMenu: (state, action) => {
      const { payload }: any = action;
      state.activeMenu = payload;
    },
    updatePage: (state, action) => {
      const { payload }: any = action;
      state.page = payload;
    },
    setBlockNavigate: (state, action) => {
      const { payload }: any = action;
      state.blockNavigation = payload;
    },
    updateSearchedLocations: (state, action) => {
      const { payload }: any = action;
      state.searchedLocations = payload;
    },
    setPresignedUrl: (state, action) => {
      const { payload }: any = action;
      state.presignedUrl = payload;
    },
    setBaseUrl: (state, action) => {
      const { payload }: any = action;
      state.baseUrl = payload;
    },
  },
});

export const {
  setData,
  setCommonDataLoader,
  setCommonMenu,
  updateActionApiStatus,
  updateActiveMenu,
  updatePage,
  setBlockNavigate,
  viewChatMobile,
  updateSearchedLocations,
  setPresignedUrl,
  setBaseUrl,
} = commonSlice.actions;

export default commonSlice.reducer;

export const getAndSetData = async (
  type: string,
  query: string = '',
  emptyCags: boolean = false,
) => {
  const { dispatch } = store;
  const apiEndpoint =
    type === 'country'
      ? endPoints.common.countryList
      : type === 'department'
      ? endPoints.common.deparmentList
      : endPoints.common.categories;

  dispatch(setCommonDataLoader(true));

  getApiCall(
    `${apiEndpoint}${query}`,
    (s: any) => {
      const { data: data } = s;
      if (data.data) {
        dispatch(setData({ type: type, value: data.data, emptyCags }));
      }
      dispatch(setCommonDataLoader(false));
    },
    (e: any) => {
      dispatch(setCommonDataLoader(false));
      notify(`Error while fetching ${type} list`, 'error');
    },
  );
};

export const updatePaginationPage = (page: number) => {
  const { dispatch } = store;
  dispatch(updatePage(page));
};

export async function fetchLocationByText(location: string) {
  const { dispatch } = store;
  console.log(location, 'loc');

  const apiKey = process.env.GOOGLE_API_KEY;
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=${apiKey}`,
  );
  response.json().then((data) => {
    if (data.results) {
      dispatch(updateSearchedLocations(data.results));
      console.log(data);
    }
  });
}

// export const getSignedUrl = (
//   fileName: string,
//   cB: (flag: boolean, url: string) => void,
// ) => {
//   getApiCall(
//     endPoints.common.signedUrl,
//     (d) => {
//       console.log('d-->', d);
//     },
//     (e) => {
//       console.log(e);
//     },
//   );
// };

// export const uploadToS3 = async (file: File): Promise<UploadedFile> => {
//   try {
//     // Step 1: Get the presigned URL and baseUrl
//     const { preSignedUrl, baseUrl } = await getPresignedUrl(file.name);

//     // Step 2: Define headers for the PUT request
//     const headers = {
//       'Content-Type': file.type,
//     };

//     // Step 3: Convert the file to a Blob (optional as `File` extends `Blob`)
//     const fileBlob = new Blob([file], { type: file.type });

//     // Step 4: Upload the file to S3 using the presigned URL
//     const response = await fetch(preSignedUrl, {
//       method: 'PUT',
//       headers,
//       body: fileBlob,
//     });

//     // Check if the upload was successful
//     if (response.ok) {
//       const uploadedFileUrl = `${baseUrl}${file.name}`;
//       return { url: uploadedFileUrl, name: file.name };
//     } else {
//       throw new Error(`File upload failed with status: ${response.status}`);
//     }
//   } catch (error) {
//     console.error('Upload failed:', error);
//     throw new Error('Upload failed');
//   }
// };

export const uploadToS3 = async (
  file: File,
  onProgress?: (progress: number) => void,
): Promise<UploadedFile> => {
  try {
    // Step 1: Get the presigned URL and baseUrl
    const { preSignedUrl, baseUrl } = await getPresignedUrl(file.name);

    // Step 2: Define headers for the PUT request
    const headers = {
      'Content-Type': file.type,
    };

    // Step 3: Convert the file to a Blob (optional as `File` extends `Blob`)
    const fileBlob = new Blob([file], { type: file.type });

    // Step 4: Use XMLHttpRequest to upload the file and track progress
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open('PUT', preSignedUrl, true);

      // Set headers
      Object.entries(headers).forEach(([key, value]) => {
        xhr.setRequestHeader(key, value as string);
      });

      // Track upload progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable && onProgress) {
          const progress = Math.round((event.loaded / event.total) * 100);
          onProgress(progress);
        }
      };

      // Handle completion
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          const uploadedFileUrl = `${baseUrl}${file.name}`;
          resolve({ url: uploadedFileUrl, name: file.name });
        } else {
          reject(new Error(`File upload failed with status: ${xhr.status}`));
        }
      };

      // Handle errors
      xhr.onerror = () =>
        reject(new Error('Upload failed due to a network error'));

      // Send the file
      xhr.send(fileBlob);
    });
  } catch (error) {
    console.error('Upload failed:', error);
    throw new Error('Upload failed');
  }
};

export const uploadToS3WithPresigedUrl = async (
  file: File,
  url: { preSignedUrl: string; baseUrl: string },
  onProgress?: (progress: number) => void,
): Promise<{ url: string; name: string }> => {
  try {
    // Step 2: Define headers for the PUT request
    const headers = {
      'Content-Type': file.type,
    };
    const { preSignedUrl, baseUrl } = url;
    // Step 3: Convert the file to a Blob (optional as `File` extends `Blob`)
    const fileBlob = new Blob([file], { type: file.type });

    // Step 4: Use XMLHttpRequest to upload the file and track progress
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', preSignedUrl, true);
      // Set headers
      Object.entries(headers).forEach(([key, value]) => {
        xhr.setRequestHeader(key, value as string);
      });

      // Track upload progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable && onProgress) {
          const progress = Math.round((event.loaded / event.total) * 100);
          onProgress(progress);
        }
      };

      // Handle completion
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          const uploadedFileUrl = `${baseUrl}${file.name}`;
          resolve({ url: uploadedFileUrl, name: file.name });
        } else {
          reject(new Error(`File upload failed with status: ${xhr.status}`));
        }
      };

      // Handle errors
      xhr.onerror = () =>
        reject(new Error('Upload failed due to a network error'));

      // Send the file
      xhr.send(fileBlob);
    });
  } catch (error) {
    console.error('Upload failed:', error);
    throw new Error('Upload failed');
  }
};

//For single signed url and upload
export const getPresignedUrl = async (
  fileName: string,
): Promise<{ preSignedUrl: string; baseUrl: string }> => {
  const apiEndpoint = endPoints.common.signedUrl;
  const { dispatch } = store;
  return new Promise((resolve, reject) => {
    dispatch(startLoading(MediaUploadLoader));
    getApiCall(
      `${apiEndpoint}?filename=${fileName}`,
      (response: any) => {
        const { data } = response;
        if (data && data.data && data.data.preSignedUrl && data.data.baseUrl) {
          dispatch(setPresignedUrl(data.data.preSignedUrl));
          dispatch(setBaseUrl(data.data.baseUrl));
          resolve({
            preSignedUrl: data.data.preSignedUrl,
            baseUrl: data.data.baseUrl,
          });
        } else {
          reject('No presigned URL or base URL found in the response');
        }
      },
      (error: any) => {
        console.error('Error while fetching presigned URL:', error);
        dispatch(stopLoading(MediaUploadLoader));
        reject('Error while fetching presigned URL');
      },
    );
  });
};

//For multiple signed urls
export const getPresignedUrls = async (
  queryNames: string,
): Promise<{ preSignedUrl: string; baseUrl: string; filename: string }[]> => {
  const { dispatch } = store;

  return new Promise((resolve, reject) => {
    dispatch(startLoading(MediaUploadLoader));
    getApiCall(
      `${endPoints.common.signedUrls}?${queryNames}`,
      (response: any) => {
        const { data } = response;
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject('No presigned URL or base URL found in the response');
        }
      },
      (error: any) => {
        console.error('Error while fetching presigned URL:', error);
        dispatch(stopLoading(MediaUploadLoader));
        reject('Error while fetching presigned URL');
      },
    );
  });
};

export const uploadFilesToBE = async (
  file: File,
  onProgress?: (progressMap: Record<string, number>) => void,
): Promise<UploadedFile> => {
  try {
    let url: string = '';
    let progressMap: Record<string, number> = {};
    const authToken = store.getState().auth.token;
    const formData = new FormData();
    formData.append('file', file);

    await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open(
        'POST',
        `${process.env.API_URL}${endPoints.common.uploadFileBE}`,
        true,
      );
      xhr.setRequestHeader(
        'timezone',
        Intl.DateTimeFormat().resolvedOptions().timeZone,
      );
      xhr.setRequestHeader('language', 'en');
      xhr.setRequestHeader(
        'offset',
        `${new Date().getTimezoneOffset() * 60 * 1000}`,
      );
      xhr.setRequestHeader('authorization', `Bearer ${authToken}`);
      xhr.setRequestHeader('platform', `3`);
      xhr.setRequestHeader('api_key', '1234');
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable && onProgress) {
          progressMap = {
            ...progressMap,
            [file.name]: Math.round((event.loaded / event.total) * 100),
          };
          onProgress(progressMap);
        }
      };

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          const response = JSON.parse(xhr.responseText);
          url = response.data;
          resolve(response);
        } else {
          reject(new Error(`File upload failed with status: ${xhr.status}`));
        }
      };

      xhr.onerror = () =>
        reject(new Error('Upload failed due to a network error'));

      xhr.send(formData);
    });

    return { url, name: file.name };
  } catch (error) {
    console.error('Upload failed:', error);
    throw new Error('Upload failed');
  }
};
