import { notify } from './toastify';
import { uploadFilesToBE, uploadToS3 } from '../redux/common.slice';

//After changing any config here, Make sure do to the in MediaUploadSection.tsx file
export const commonUploadFn = async (
  file: File,
  setProgress: undefined | any = undefined,
  callBack: undefined | ((url: string | null) => void) = undefined,
) => {
  const newFileName = `${Date.now()}_${file.name}`;
  const renamedFile = new File([file], newFileName, { type: file.type });
  const resp = await uploadToS3(renamedFile, (progress) => {
    setProgress && setProgress(progress);
  });
  if (resp.url) {
    callBack && callBack(resp.url);
  } else {
    notify('Error while uploading, Please try again.', 'error');
    callBack && callBack(null);
  }
};
interface UploadedFile {
  url: string;
  name: string;
}
