import React, { useEffect, useState } from 'react';
import NoInternet from '../Components/NoInternet';

const NetworkStatus: React.FC = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const updateNetworkStatus = () => {
    setTimeout(() => {
      setIsOnline(navigator.onLine);
    }, 1000);
  };

  useEffect(() => {
    window.addEventListener('online', updateNetworkStatus);
    window.addEventListener('offline', updateNetworkStatus);

    return () => {
      window.removeEventListener('online', updateNetworkStatus);
      window.removeEventListener('offline', updateNetworkStatus);
    };
  }, []);

  return (
    <div className="main">
      {children}
      {/* <header className="header">
          <div
            className="logo"
            // onClick={() => {
            //   status ? navigate(ROUTES.DASHBOARD) : navigate(ROUTES.LOGIN);
            // }}
          >
            <img src={Images.LOGO} alt={AIT().logo} />
          </div>
        </header> */}
      {!isOnline && (
        <NoInternet
          heading="No Internet Connection"
          title="The features in this area require Internet connectivity. Please connect your computer to the Internet and try again."
          buttonText="Refresh"
          handleClick={() => window.location.reload()}
          showButton={true}
        />
      )}
    </div>
  );
};

export default NetworkStatus;
