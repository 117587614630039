import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../redux/store';
import Images from '../Utils/images';
import { useTranslation } from 'react-i18next';

interface Props {
  status?: boolean;
}

export default function AccountStatus(props: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'account_status_en',
  });
  const {
    rejectBanner,
    rejectReason,
    userData: { role, permissions },
  } = useAppSelector((state) => state?.auth || {});
  const navigate = useNavigate();

  if (role === 'BRANCH_MANAGER') {
    return (
      <div
        className={`accountStatus ${rejectBanner === 'REJECTED' && 'rejected'}`}
      >
        <figure
        // disableRipple
        // onClick={() =>
        //   rejectBanner === 'REJECTED'
        //     ? updateRejectBanner('')
        //     : navigate('/company')
        // }
        >
          <img src={Images.WARNING} alt="Under Review" />
        </figure>

        <p onClick={() => navigate('/company')}>
          <span>{t('account_status')}</span>{' '}
          {t('kindly_contact_your_head_branch_to_resolve_this_issue')}
        </p>
      </div>
    );
  }

  if (role === 'CO_VENDOR') {
    return (
      <div
        className={`accountStatus ${rejectBanner === 'REJECTED' && 'rejected'}`}
      >
        <figure>
          <img
            src={rejectBanner === 'REJECTED' ? Images.ERROR_IC : Images.WARNING}
            alt="Under Review"
          />
        </figure>
        {rejectBanner === 'PENDING' ? (
          <p
            onClick={() => {
              if ('Company Management' in permissions) {
                navigate('/company');
              }
            }}
          >
            <span>{t('account_status')}</span>{' '}
            {t('your_account_is_under_review_by_admin')}
          </p>
        ) : (
          <p>
            <span>{t('onboarding_request_rejected')}</span>{' '}
            {rejectReason?.rejectReason || ''}{' '}
            {'Company Management' in permissions ? (
              <>
                .{t('click')}{' '}
                <span
                  className="click"
                  onClick={() => {
                    if ('Company Management' in permissions) {
                      navigate('/company');
                    }
                  }}
                >
                  {t('here')}
                </span>{' '}
                {t('to_update_your_details')}
              </>
            ) : (
              ''
            )}
          </p>
        )}
      </div>
    );
  }

  return (
    <div
      className={`accountStatus ${rejectBanner === 'REJECTED' && 'rejected'}`}
    >
      <figure>
        <img
          src={rejectBanner === 'REJECTED' ? Images.ERROR_IC : Images.WARNING}
          alt="Under Review"
        />
      </figure>
      {rejectBanner === 'PENDING' ? (
        <p onClick={() => navigate('/company')}>
          <span>Account Status:</span> Your account is under review by Admin
        </p>
      ) : (
        <p onClick={() => navigate('/company')}>
          <span>Onboarding Request Rejected:</span>{' '}
          {rejectReason?.rejectReason || ''} .Click{' '}
          <span className="click">here</span> to update your details
        </p>
      )}
    </div>
  );
}
