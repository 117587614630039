import React, { ChangeEvent, useState } from 'react';
import { Box, FormLabel, LinearProgress, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import CustomPopover from './CustomPopover';
import { notify } from '../Utils/toastify';
import withDragDrop from './WithDragAndDrop';
import { uploadToS3 } from '../redux/common.slice';
interface Props {
  id: string;
  label?: string;
  placeholder?: string;
  name: string;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  defaultValue?: string | number;
  variant?: 'outlined' | 'standard' | 'filled' | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?:
    | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  value?: string;
  startAdornment?: React.ReactElement | null;
  endAdornment?: React.ReactElement | null;
  control?: null | any;
  tooltip?: boolean;
  popoverContent?: React.ReactElement | null;
  endAdornmentIcon?: React.ReactElement;
  setValue?: any;
  sizeLimit?: number;
  sizeLimitError?: string;
  showCard?: boolean;
  s3Upload?: boolean;
  s3fileUrl?: string;
  setS3FileUrl?: (url: string) => void;
  requiredField?: boolean | any;
  schema?: any;
  onDrop?: (files: FileList) => void;
  progress: number;
  setProgress?: any;
  clearErrors?: any;
  isImage?: any | boolean;
}

const FileInput: React.FC<Props> = (props) => {
  const {
    id,
    label,
    placeholder = '',
    name,
    helperText,
    error = false,
    disabled = false,
    defaultValue,
    variant = 'outlined',
    value,
    onBlur,
    startAdornment = null,
    endAdornment = null,
    onChange,
    control,
    tooltip,
    popoverContent,
    setValue,
    sizeLimit = 3 * 1024 * 1024,
    showCard = false,
    sizeLimitError = 'File size exceed',
    s3Upload = false,
    setS3FileUrl,
    s3fileUrl,
    requiredField,
    schema,
    progress,
    setProgress,
    clearErrors,
    isImage,
  } = props;

  const imageRef = React.useRef<HTMLInputElement>(null);
  const [fileKey, setFileKey] = useState(Date.now());

  const handleClick = () => {
    if (imageRef && imageRef.current) {
      imageRef.current.click();
    }
  };

  const handleDelete = () => {
    if (s3fileUrl) {
      setS3FileUrl && setS3FileUrl('');
    }
    setValue(name, undefined);
  };

  const handleUpload = async (file: File) => {
    uploadToS3(file, (progress) => {
      setProgress(progress === 100 ? 0 : progress);
    })
      .then((resp) => {
        setS3FileUrl && setS3FileUrl(resp.url);
        clearErrors && clearErrors(name);
      })
      .catch((e) => {
        console.log(e);
      });
    // return;
    // commonUploadFn(file, setProgress, (url) => {
    //   if (url) {
    //     setS3FileUrl && setS3FileUrl(url);
    //     clearErrors && clearErrors(name);
    //   } else {
    //     setValue(name, undefined);
    //   }
    //   setProgress(0);
    // });
  };

  // Supported image formats
  const imageFormats: Array<string> = ['jpeg', 'png', 'jpg', 'PNG', 'JPEG'];

  // Handles file change and validation logic
  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files?.length) return; // Exit if no files were selected

    const file = files[0];

    // Validate image format if the input is an image
    if (isImage) {
      let fileType = file?.type?.split('/')[1]?.toLowerCase();
      if (!imageFormats.includes(fileType)) {
        notify('Allowed formats: .png, .jpg, .jpeg', 'error');
        return;
      }
    }

    // Handle size validation
    if (file.size > sizeLimit) {
      notify(sizeLimitError, 'error');
      e.target.value = ''; // Clear file input to allow the error to trigger again
      return;
    }

    // Delete the previous file if a new file is uploaded
    if (s3fileUrl) handleDelete();

    // Set the file in form state
    if (setValue) setValue(name, file);

    // Validate the file against a provided schema, if any
    if (schema) {
      try {
        await schema.validateAt(name, { [name]: file });
      } catch (error: any) {
        notify(error.message, 'error');
        setValue(name, null); // Clear the file if validation fails
        return;
      }
    }

    // If S3 upload is enabled, handle the file upload
    if (s3Upload) {
      handleUpload(file);
    }
    setFileKey(Date.now());
  };

  return (
    <Box>
      <FormLabel className="formLabel">
        {label} {requiredField ? <sup>*</sup> : null}
        {tooltip ? (
          <>
            <CustomPopover
              id=""
              children={popoverContent}
              arrow
              anchorOrigin_vertical="top"
              anchorOrigin_horizontal="center"
              transformOrigin_vertical="bottom"
              transformOrigin_horizonral="center"
            />
          </>
        ) : null}
      </FormLabel>
      {control ? (
        <Controller
          control={control}
          name={name}
          render={({ field }) => {
            return (
              <div className="fileSelection">
                <input
                  name="picture"
                  key={fileKey}
                  type="file"
                  ref={imageRef}
                  aria-hidden="true"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  accept=".jpg , .jpeg , .png , .pdf, .doc , .docx"
                  className="dddd"
                />
                <TextField
                  id={id}
                  type="text"
                  helperText={progress === 0 ? helperText : ''}
                  variant={variant}
                  error={progress === 0 && error}
                  placeholder={placeholder}
                  disabled={disabled}
                  color="primary"
                  size="small"
                  {...field}
                  value={s3fileUrl && field.value ? field.value.name : ''}
                  fullWidth
                  onClick={handleClick}
                  InputProps={{
                    startAdornment,
                    autoComplete: 'off',
                    readOnly: true,
                    endAdornment,
                  }}
                  className="fileInputField"
                />
                {showCard && (progress !== 0 || s3fileUrl) && (
                  <div className="fileProgress">
                    {progress !== 0 && progress !== 100 && (
                      <div className="linearProgress">
                        <LinearProgress
                          variant="determinate"
                          value={progress}
                        />
                        <p>{progress}%</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          }}
        />
      ) : (
        <>
          <input
            name="picture"
            type="file"
            ref={imageRef}
            aria-hidden="true"
            style={{ display: 'none' }}
            onChange={(e: any) => {}}
            accept=".jpg , .jpeg , .png , .svg , .webp"
            className="dddd"
          />
          <TextField
            id={id}
            size="small"
            name={name}
            type="text"
            helperText={helperText}
            variant={variant}
            error={error}
            placeholder={placeholder}
            disabled={disabled}
            color="primary"
            // value={value || ''}
            // onChange={onChange}
            defaultValue={defaultValue}
            fullWidth
            onClick={handleClick}
            InputProps={{
              startAdornment,
              autoComplete: 'off',
              endAdornment,
              readOnly: true,
            }}
            className="fileInputField"
          />
        </>
      )}
    </Box>
  );
};

export const FileInputWithDND = withDragDrop(FileInput);
export default FileInput;
