import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import store, {
  AppDispatch,
  RootState,
  useAppSelector,
} from '../../redux/store';
import { Socket, io } from 'socket.io-client';
import { useDispatch } from 'react-redux';
import {
  addChats,
  addMessage,
  checkUserAndUpdateStatus,
  lastReadMessage,
  orderCheckedOut,
  resetChat,
  setActiveChat,
  setLoadingChats,
  setLoadingMessage,
  setNewMessages,
  setUnreadZero,
} from '../../redux/chat.slice';
import {
  CHAT_ACTIVE,
  ROUTES,
  USER_TYPE_MAP,
  USER_VENDOR_CHAT,
  VENDOR_ADMIN_CHAT,
} from '../../helpers/contants';
import { BusinessChatType, Chat, ChatPage, ContactDetails } from '../../types';
import { useCircularQueue } from './hooks/useCircularQueue';
import { apiSuccessCode, postApiCall } from '../../api/methods';
import endPoints from '../../api/endpoint';
import { createChat } from '../../helpers/functions';
import { fetchNoticationList } from '../../redux/notification.slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { deepClone } from '../../Utils/helperFns';
import { setBookingList } from '../../redux/cab.slice';
import { selectLoader } from '../../redux/loaders.slice';
import { BookingListLoader } from '../../types/loaderTypes';

interface Props {
  children: ReactNode;
}
export const PAGE_LIMIT = 100;
export type SocketEvents =
  | 'connected'
  | 'messageListing'
  | 'chatListing'
  | 'joinRoom'
  | 'sendMessage'
  | 'user_status'
  | 'messageRead'
  | 'delete_message'
  | 'leaveRoom'
  | 'updateMedia'
  | 'vendorBookingStatus';
export type EmitEventFunction = (
  type: SocketEvents,
  params?: any,
  additionalInfo?: any,
) => void;
export type NewchatReturn = {
  newChat: Chat;
  chatId: string;
  newMessage: {
    chatId: string;
    messages: [];
  };
};
export interface SocketContextType {
  client: Socket | null;
  connectionStatus: boolean;
  handleEmitEvent: EmitEventFunction;
  activeChat: (props: ActiveChat) => void;
  createNewchat: (props: CreateNewChatProps) => Promise<NewchatReturn>;
  userHasChat: (userId: string) => string | boolean;
}
export interface ActiveChat {
  chatId: string;
  contactId?: string;
  newChat?: boolean;
}
export interface CreateNewChatProps {
  senderId: string;
  receiverId: string;
  contactDetails: ContactDetails;
  businessChatType: string;
  contactId: string;
  contactType: 'user' | 'vendor';
  chatType: 'ONE_TO_ONE';
}
const SocketContext = createContext<SocketContextType | null>(null);
const CHAT_SOCKET_URL: string = process.env.SOCKET_URL || '';

export const useSocket = (): SocketContextType | null =>
  useContext(SocketContext);
export const SocketProvider = ({ children }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const skipEvent = useRef<Partial<Record<SocketEvents, any>>>({});
  const { messages, fileProgress, currentActiveUserId, chats } = useAppSelector(
    (state: RootState) => state.chat,
  );
  const { bookingList } = useAppSelector((s) => s.cab);
  const bookingLoader = useAppSelector((s) =>
    selectLoader(s, BookingListLoader),
  );
  const { token, userData } = useAppSelector((state: RootState) => state.auth);
  const [socketClient, setSocketClient] = useState<Socket | null>(null);
  // All refs for data accesssing
  const socketRef = useRef<Socket | null>(null);
  const listingFetched = useRef<Record<string, boolean>>({});
  const listenerAdded = useRef<boolean>(false);
  const pagesRef = useRef<Record<string, ChatPage>>({});
  const [connectionStatus, setConnectionStatus] = useState<boolean>(false);
  const messageQueue = useCircularQueue(20);
  const branchId = userData?.branchId;
  const currentUserId = userData?._id || userData?.userId;

  const setLoading = (chatId: string) => {
    dispatch(setLoadingMessage({ chatId, isLoading: true }));
  };
  const loadingChats = (value: boolean) => {
    dispatch(setLoadingChats(value));
  };

  const handleEmitEvent = (
    type: SocketEvents,
    params: any = {},
    additionalInfo?: any,
  ) => {
    if (socketClient?.connected) {
      switch (type) {
        case 'connected': {
          socketClient.emit('connected', params);
          break;
        }
        case 'chatListing': {
          socketClient.emit('chatListing', params);
          loadingChats(true);
          break;
        }
        case 'joinRoom': {
          if (additionalInfo?.noFetchMessage) {
            skipEvent.current = {
              messageListing: true,
            };
          }
          socketClient.emit('joinRoom', params);
          break;
        }
        case 'leaveRoom': {
          socketClient.emit('leaveRoom', params);
          break;
        }
        case 'messageListing': {
          const chatId = params.chatId;
          let pageNo = 0;
          if (chatId && pagesRef.current[chatId]) {
            pageNo = pagesRef.current[chatId].nextPage;
            if (!pagesRef.current[chatId].hasMore) {
              // console.log('no-more-pages', pagesRef.current);
              return;
            }
          }
          const listingParams = { ...params, pageNo, limit: PAGE_LIMIT };
          // console.log(listingParams, 'listing-params');
          socketClient.emit('messageListing', listingParams);
          break;
        }
        case 'sendMessage': {
          // console.log('sending this------>', params)
          socketClient.emit('sendMessage', params);
          break;
        }
        case 'user_status': {
          socketClient.emit('user_status', params);
          break;
        }
        case 'messageRead': {
          socketClient.emit('messageRead', params);
          break;
        }
        case 'updateMedia': {
          socketClient.emit('updateMedia', params);
          break;
        }
        case 'vendorBookingStatus': {
          socketClient.emit('vendorBookingStatus', params);
          break;
        }
        default: {
          console.log('not an event');
        }
      }
    } else {
      console.log(
        'Emitting connection failed. Socket not connected. EVENT TYPE ' + type,
      );
    }
  };

  const handleConnect = () => {
    handleEmitEvent('connected');
  };
  const handleDisconnect = (data: any) => {
    // console.log('disconnectin', new Date().toUTCString());
    setConnectionStatus(false);
    disconnectSocket();
  };
  const handleConnectError = (data: any) => {
    if (socketClient) {
      const newSocketClient = socketClient;
      newSocketClient.io.opts.transports = ['websocket', 'polling'];
      setSocketClient(newSocketClient);
      socketRef.current = newSocketClient;
      listingFetched.current = {};
      pagesRef.current = {};
    }
    setConnectionStatus(false);
  };
  const handleConnected = (data: any) => {
    console.log('socket connected', new Date().toUTCString());
    setConnectionStatus(data.statusCode === apiSuccessCode.success);
    handleEmitEvent('chatListing');
  };
  const handleChatListing = (data: any) => {
    dispatch(addChats(data.data));
  };
  const handleMessageListing = (data: any) => {
    const chatId = data?.data[0]?.chatId;
    if (!chatId) {
      return;
    }
    // set the fetched listing in the message listing response
    listingFetched.current = {
      ...listingFetched.current,
      [chatId]: true,
    };
    dispatch(setNewMessages({ messages: data.data, chatId }));
  };
  const handleSendMessage = (data: any) => {
    console.log('data', data);
    try {
      const messageId = `${data.data._id}`;
      if (messageQueue.find(messageId)) {
        // console.log('message-already-added-send', data);
        return;
      }
      const id =
        data.data.businessChatType === USER_VENDOR_CHAT
          ? branchId
          : currentUserId;
      if (data.data.senderId !== id) {
        handleEmitEvent('messageRead', {
          messageId: data.data._id,
          businessChatType: data.data.businessChatType,
        });
      }
      dispatch(
        addMessage({
          message: { ...data.data, status: CHAT_ACTIVE },
          callback: () => messageQueue.add(messageId),
          currentUserId: id,
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleReceiveMessage = (data: any) => {
    try {
      console.log('receve', data);
      if (data?.data?.lastMessageDetail) {
        if (data?.data?.lastMessageDetail.messageType == 'QUOTATION') {
          dispatch(fetchNoticationList({ page: 1, pageSize: 5 }));
        }
        const messageId = `${data?.data?.lastMessageDetail._id}`;
        if (messageQueue.find(messageId)) {
          return;
        }
        const id =
          data.data.businessChatType === USER_VENDOR_CHAT
            ? branchId
            : currentUserId;
        dispatch(
          addMessage({
            message: {
              ...data.data.lastMessageDetail,
              status: CHAT_ACTIVE,
              createdAt: new Date(
                data.data.lastMsgCreated || new Date(),
              ).toISOString(),
            },
            chatId: data.data._id,
            chat: data.data,
            // it will be only true if it's not same type chat
            isRecevieMessage: data.data.businessChatType !== USER_VENDOR_CHAT,
            currentUserId: id,
            handleEmitEvent,
            callback: () => messageQueue.add(messageId),
          }),
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleJoinRoom = (data: any) => {
    if (skipEvent.current.messageListing) {
      skipEvent.current.messageListing = false;
      return;
    }
    if (!listingFetched.current[data.data.chatId]) {
      handleEmitEvent('messageListing', {
        chatId: data.data.chatId,
        limit: PAGE_LIMIT,
      });
      setLoading(data.data.chatId);
    } else {
      console.log('already-fetched');
    }
  };
  const handleReadMessage = (data: any) => {
    if (data.chatId && data.receiverId !== currentUserId) {
      dispatch(
        lastReadMessage({
          lastRead: data.readTime,
          chatId: data.chatId,
        }),
      );
    }
    // console.log('Event -> messageRead', data);
  };
  const handleUserStatus = (data: any) => {
    dispatch(
      checkUserAndUpdateStatus({ contactId: data.contactId, status: data }),
    );
  };
  const handleOrderPlaced = (data: any) => {
    dispatch(orderCheckedOut(data.data));
  };
  const handleUserOnline = (data: any) => {
    dispatch(
      checkUserAndUpdateStatus({ contactId: data.contactId, status: data }),
    );
  };

  const handleOrderReceieved = (data: any) => {
    dispatch(fetchNoticationList({ page: 1, pageSize: 5 }));
  };

  const handleBookingUpdate = (data: any) => {
    console.log('data', data);

    if (bookingList && bookingList.data && bookingList.data.length > 0) {
      const listClone = deepClone(bookingList);
      const index = listClone.data.findIndex((b) => b._id === data.bookingId);
      if (index !== -1) {
        listClone.data[index].bookingStatus = data.bookingStatus;
      }
      dispatch(setBookingList(listClone));
    }
  };

  useEffect(() => {
    if (socketClient && !listenerAdded.current) {
      socketClient?.on('connect', handleConnect);
      socketClient?.on('disconnect', handleDisconnect);
      socketClient?.on('connect_error', handleConnectError);
      socketClient?.on('connected', handleConnected);
      //chat socket listeners
      socketClient?.on('chatListing', handleChatListing);
      socketClient?.on('roomJoined', handleJoinRoom);
      socketClient?.on('messageListing', handleMessageListing);
      socketClient?.on('sendMessage', handleSendMessage);
      socketClient?.on('user_status', handleUserStatus);
      socketClient?.on('receiveMessage', handleReceiveMessage);
      socketClient?.on('user_online', handleUserOnline);
      socketClient?.on('messageRead', handleReadMessage);
      socketClient?.on('orderPlaced', handleOrderPlaced);
      socketClient?.on('orderReceived', handleOrderReceieved);
      socketClient?.on('newNotification', handleOrderReceieved);
      listenerAdded.current = true;
    }
  }, [socketClient]);

  // useEffect(() => {
  //   if (bookingList && bookingList.data.length > 0) {
  //     socketClient?.on('bookingStatusUpdated', handleBookingUpdate);
  //     socketClient?.repla('bookingStatusUpdated')
  //   } else {
  //     socketClient?.removeListener('bookingStatusUpdated', handleBookingUpdate);
  //   }
  // }, [bookingList]);

  const disconnectSocket = useCallback(() => {
    if (socketClient) {
      socketClient.removeAllListeners();
      socketClient.disconnect();
      setSocketClient(null);
    }
    listenerAdded.current = false;
    socketRef.current = null;
    listingFetched.current = {};
    pagesRef.current = {};
  }, [socketClient, token]);

  const reConnect = useCallback(() => {
    if (token && token.length && CHAT_SOCKET_URL) {
      let clientInstance: any;
      //Initializing socket connection as the component loads.
      clientInstance = io(CHAT_SOCKET_URL, {
        query: {
          accessToken: token,
          userType: 'VENDOR',
          state: true,
        },
        transports: ['websocket', 'polling'],
      });
      socketRef.current = clientInstance;
      setSocketClient(clientInstance);
    } else {
      console.log('call-disconnect');
      disconnectSocket();
      dispatch(resetChat(null));
    }
  }, [token, disconnectSocket]);

  useEffect(() => {
    reConnect();
  }, [token]);

  useEffect(() => {
    if (!socketClient) {
      setSocketClient(socketRef.current);
    }
  }, [socketClient]);

  const activeChat = ({
    chatId,
    contactId = '',
    newChat = false,
  }: ActiveChat) => {
    if (!chatId) {
      return;
    }
    const state: RootState = store.getState();
    const currentChats = state.chat.chats;
    const chat: Chat =
      currentChats.filter((chat: Chat) => chat._id === chatId)[0] || {};
    const isNewChat = newChat || chat.isNewChat;
    const doNotLoad =
      !!fileProgress[chatId] || !!listingFetched.current[chatId];
    const doNotFetch = !!fileProgress[chatId];
    dispatch(setActiveChat(chatId));
    if (currentActiveUserId) {
      handleEmitEvent('leaveRoom', {
        contactId: currentActiveUserId,
      });
    }
    const newContactId = chat ? chat.contactDetails?._id : contactId;
    handleEmitEvent(
      'joinRoom',
      {
        ...(chat.businessChatType === VENDOR_ADMIN_CHAT
          ? { chatId }
          : { contactId: newContactId }),
        businessChatType: chat.businessChatType,
      },
      {
        noFetchMessage: isNewChat || doNotFetch,
      },
    );
    if (chat.businessChatType) {
      handleEmitEvent('user_status', {
        contactId: newContactId,
        businessChatType: chat.businessChatType,
        userType: USER_TYPE_MAP[chat.businessChatType as BusinessChatType],
      });
    }
    dispatch(
      setLoadingMessage({
        chatId: chatId,
        isLoading: isNewChat ? false : !doNotLoad,
      }),
    );
    if (chat.businessChatType) {
      handleEmitEvent('user_status', {
        contactId: newContactId,
        businessChatType: chat.businessChatType,
        userType: USER_TYPE_MAP[chat.businessChatType as BusinessChatType],
      });
    }
    dispatch(setUnreadZero(chatId));
  };

  const createNewchat = ({
    senderId,
    receiverId,
    contactDetails,
    contactId,
    contactType,
    chatType,
    businessChatType,
  }: CreateNewChatProps): Promise<NewchatReturn> => {
    return new Promise((resolve, reject) => {
      postApiCall(
        endPoints.chat.createChat,
        {
          contactId: contactId,
          contactType: contactType,
          businessChatType: businessChatType,
          type: chatType,
        },
        ({ data }: any) => {
          try {
            const { newChat } = createChat({
              chatId: data.chatId,
              senderId,
              receiverId,
              contactDetails,
              businessChatType,
            });
            listingFetched.current = {
              ...listingFetched.current,
              [data.chatId]: true,
            };
            resolve({
              newChat,
              chatId: data.chatId,
              newMessage: {
                chatId: data.chatId,
                messages: [],
              },
            });
          } catch (error) {
            reject(error);
          }
        },
        (error: any) => {
          reject(error);
        },
      );
    });
  };

  const userHasChat = (userId: string) => {
    const hasChat: Chat = chats.filter(
      (chat: Chat) => chat.contactDetails?._id === userId,
    )[0];
    if (hasChat) {
      return `${hasChat._id}`;
    }
    return false;
  };

  const contextValues: SocketContextType = {
    client: socketClient,
    connectionStatus,
    handleEmitEvent,
    activeChat,
    createNewchat,
    userHasChat,
  };

  useEffect(() => {
    pagesRef.current = messages.page;
  }, [messages]);

  return (
    <SocketContext.Provider value={contextValues}>
      {children}
    </SocketContext.Provider>
  );
};
