import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { RootState, useAppSelector } from '../../../redux/store';
import AutocompleteSelect from '../../../Components/AutocompleteSelect';
import CustomButton from '../../../Components/CustomButton';
import CustomPopover from '../../../Components/CustomPopover';
import CustomSelect from '../../../Components/CustomSelect';
import InputField from '../../../Components/Input';
import { AIT } from '../../../Utils/Localization';
import { FORM_STEPS, businessType } from '../../../Utils/constantData';
import Images from '../../../Utils/images';
import { notify } from '../../../Utils/toastify';
import { BusinessSchema } from '../../../Utils/yupvalidations';
import { ROUTES } from '../../../helpers/contants';
import {
  saveUserData,
  stepRegisterCall,
  updateApiStatus,
  updateStep,
} from '../../../redux/auth.slice';
import { ApiStatus } from '../../../types';
import { useTranslation } from 'react-i18next';
import AutoCompleteLocation from '../../../Components/AutoCompleteLocation';

interface BusinessTypeWarning {
  title: string;
  info: any;
  error: any;
}
function BusinessTypeWarning(props: BusinessTypeWarning) {
  const { title, info, error } = props;
  return (
    <div className="passwordSugession BusinessTypeWarning">
      <div className="popoverHead">
        <img src={Images.WARNING} alt={AIT().warning} />
      </div>
      <div className="popOverBody">
        <h4>{title}</h4>
        <p className="info">{info}</p>
        <p className="error">{error}</p>
      </div>
    </div>
  );
}

interface Props {
  changeStep: (step: number) => void;
}

export default function Business({ changeStep }: Props) {
  const [load, setLoad] = useState<boolean>(false);
  const [loadingData, setLodingData] = useState<boolean>(true);
  const dispatch = useDispatch();

  const { departmentList } = useAppSelector((state: RootState) => state.common);
  const { userData, apiStatus } = useAppSelector(
    (state: RootState) => state.auth,
  );
  const [latLong, setLatLong] = useState<any>(null);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
    watch,
    trigger,
    clearErrors,
  } = useForm({
    resolver: yupResolver(BusinessSchema),
    mode: 'onTouched',
  });

  useEffect(() => {
    if (userData.businessInfo) {
      setLodingData(true);

      Object.keys(BusinessSchema.fields).forEach((k: any) => {
        if (k === 'businessLocation') {
          setValue('businessLocation', { name: userData.businessInfo[k] });
          return;
        }
        if (k === 'department') {
          setValue(
            'department',
            departmentList
              .filter((d: any) => userData.businessInfo[k].includes(d._id))
              .map((d: any) => d.deptName),
          );
          return;
        }
        if (k === 'businessCoordinates') {
          return;
        }
        if (k === 'businessAddress') {
          setValue(k, {
            description: userData.businessInfo[k],
            latitude:
              userData.businessInfo['businessCoordinates'].coordinates[1],
            longitude:
              userData.businessInfo['businessCoordinates'].coordinates[0],
          });
          return;
        }

        setValue(k, userData.businessInfo[k]);
      });
      if (userData.businessInfo['businessCoordinates']) {
        setLatLong({
          lng: userData.businessInfo['businessCoordinates'].coordinates[0],
          lat: userData.businessInfo['businessCoordinates'].coordinates[1],
        });
      }
      setLodingData(false);
      trigger();
    } else {
      setLodingData(false);
    }
    return () => {
      dispatch(updateApiStatus(ApiStatus.idle));
    };
  }, [userData]);

  const onSubmit = (data: any) => {
    if (latLong === null) {
      notify('Wrong Zip / Postal code', 'error');
      return;
    }
    const payload = data;
    payload['type'] = FORM_STEPS.BUSINESS_INFO;
    payload['businessLocation'] = data['businessLocation'].name;
    payload['isBusinessInfoCorrect'] = true;
    payload['businessAddress'] =
      typeof payload['businessAddress'] === 'string'
        ? payload['businessAddress']
        : payload['businessAddress'].description;
    payload['department'] = departmentList
      .filter((d: any) => data['department'].includes(d.deptName))
      .map((d: any) => d._id);
    if (latLong && latLong.lng) {
      payload['longitude'] = latLong.lng;
      payload['latitude'] = latLong.lat;
    }
    stepRegisterCall(payload, (data: any) => {
      notify('Business Information saved successfully. ', 'success');
      dispatch(updateStep({ step: FORM_STEPS.CONTACT_INFO }));
      dispatch(
        saveUserData({
          key: 'businessInfo',
          value: data.businessInfo,
        }),
      );
      changeStep(1);
    });
  };

  const wathcType = watch('businessType');
  const wathcDepartments = watch('department');
  const fieldValue = getValues('businessType');
  const getInfoText = () => {
    if (fieldValue === 'Charity') {
      return (
        <>
          <ol>
            <li>{t('Charity.point_1')}</li>
          </ol>
          <ol>
            <li>(1) {t('Charity.point_2')}</li>
            <li>(2) {t('Charity.point_3')}</li>
            <li>(3) {t('Charity.point_4')}</li>
            <li>(4) {t('Charity.point_5')}</li>
          </ol>
        </>
      );
    } else if (fieldValue === 'State-Owned Business')
      return `${t('Charity.state_owned_business')}`;
    else if (fieldValue === 'Privately-Owned Business')
      return `${t('Charity.privately_owned_business')}`;
    else if (fieldValue === 'Publicly-Listed Business')
      return `${t('Charity.publicly_listed_business')}`;
    else if (fieldValue === 'Other') return `${t('Charity.other')}`;
  };

  const setDetails = (results: any) => {
    if (results && results.address_components) {
      const country = results.address_components.find((component: any) =>
        component.types.includes('country'),
      )?.long_name;
      const city = results.address_components.find(
        (component: any) =>
          component.types.includes('locality') ||
          component.types.includes('political'),
      )?.long_name;
      const state = results.address_components.find((component: any) =>
        component.types.includes('administrative_area_level_1'),
      )?.long_name;
      const zipCode = results.address_components.find((component: any) =>
        component.types.includes('postal_code'),
      )?.long_name;
      if (city) {
        setValue('businessCity', city);
        clearErrors('businessCity');
      } else {
        setValue('businessCity', '');
      }
      if (state) {
        setValue('businessState', state);
        clearErrors('businessState');
      } else {
        setValue('businessState', '');
      }
      if (country) {
        setValue('businessLocation', { name: country });
        clearErrors('businessLocation');
        trigger();
      } else {
        setValue('businessLocation', '');
      }
      if (zipCode) {
        setValue('businessZipCode', zipCode);
        clearErrors('businessZipCode');
        trigger();
      } else {
        setValue('businessZipCode', '');
      }
    } else {
      setValue('businessCity', '');
      setValue('businessState', '');
      setValue('businessLocation', '');
      setValue('businessZipCode', '');
    }
  };

  const { t } = useTranslation('translation', {
    keyPrefix: 'vendor_registration.business_info',
  });

  return (
    <div className="businessInfo_step">
      <form onSubmit={handleSubmit(onSubmit)}>
        {!loadingData && (
          <>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12} className="business_wa_p">
                {userData.formNextStep === undefined ||
                userData.formNextStep === FORM_STEPS.BUSINESS_INFO ? (
                  <CustomSelect
                    id="Business Type"
                    labelText={t('business_type')}
                    placeHolderText={t('please_select_business_type')}
                    name="businessType"
                    error={!!errors['businessType']}
                    helperText={
                      !!errors['businessType']
                        ? `${errors['businessType'].message}`
                        : ``
                    }
                    control={control}
                    options={businessType}
                    requiredField
                    hintText={`${t('business_type_hint')}`}
                  />
                ) : (
                  wathcType && (
                    <CustomSelect
                      id="Business Type"
                      labelText={t('business_type')}
                      placeHolderText={t('please_select_business_type')}
                      name="businessType"
                      error={!!errors['businessType']}
                      helperText={
                        !!errors['businessType']
                          ? `${errors['businessType'].message}`
                          : `${t('business_type_hint')}`
                      }
                      control={control}
                      options={businessType}
                      requiredField
                    />
                  )
                )}

                {wathcType && (
                  <CustomPopover
                    id=""
                    children={
                      <BusinessTypeWarning
                        title={t('business_type_warning.title')}
                        info={getInfoText()}
                        error={t('business_type_warning.error')}
                      />
                    }
                    anchorOrigin_vertical="top"
                    anchorOrigin_horizontal="left"
                    transformOrigin_vertical="top"
                    transformOrigin_horizonral="left"
                    closeButton
                    showHelpIcon={true}
                  />
                )}
              </Grid>

              {departmentList.length > 0 && (
                <Grid item md={4} xs={12}>
                  <AutocompleteSelect
                    requiredField
                    id="department"
                    labelText={t('departments_you_serve')}
                    placeHolderText={
                      Array.isArray(wathcDepartments) &&
                      wathcDepartments.length > 0
                        ? ``
                        : `${t('please_select_department')}`
                    }
                    name="department"
                    defaultValue={[]}
                    disabled={false}
                    control={control}
                    setValue={setValue}
                    error={!!errors['department']}
                    helperText={
                      !!errors['department']
                        ? `${errors['department'].message}`
                        : ''
                    }
                    options={departmentList.map(
                      (department: any) => department.deptName,
                    )}
                    multiple={true}
                    isCheckbox
                  />
                </Grid>
              )}
              <Grid item md={4} xs={12}>
                <InputField
                  requiredField
                  id="business_name"
                  label={t(
                    'business_name_used_for_state_or_federal_registration',
                  )}
                  name="businessName"
                  placeholder={t('enter_business_name')}
                  control={control}
                  error={!!errors['businessName']}
                  helperText={
                    !!errors['businessName']
                      ? `${errors['businessName'].message}`
                      : ''
                  }
                  charLimit={50}
                />
              </Grid>
            </Grid>
            <div className="formLabel mtb_30">
              <h3>{t('business_address')}</h3>
            </div>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <AutoCompleteLocation
                  requiredField
                  id="addressLocation"
                  labelText={t('business_address')}
                  placeHolderText={t('enter_business_address')}
                  name="businessAddress"
                  control={control}
                  setValue={setValue}
                  limit={100}
                  setLatLong={setLatLong}
                  changeCallBack={(result: any) => {
                    setDetails(result);
                  }}
                  defaultValue={watch('businessAddress')}
                  isObject={true}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <InputField
                  id="zip_postal_code"
                  label={t('zip_postal_code')}
                  name="businessZipCode"
                  placeholder={t('enter_zip_postal_code')}
                  error={!!errors['businessZipCode']}
                  helperText={
                    !!errors['businessZipCode']
                      ? errors['businessZipCode'].message
                      : ``
                  }
                  control={control}
                  requiredField
                  charLimit={9}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <InputField
                  requiredField
                  id="city_town"
                  label={t('city_town')}
                  name="businessCity"
                  placeholder={t('please_enter_city')}
                  error={!!errors['businessCity']}
                  helperText={
                    !!errors['businessCity']
                      ? errors['businessCity'].message
                      : ``
                  }
                  control={control}
                />
              </Grid>
            </Grid>
          </>
        )}

        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <InputField
              id="state"
              label={t('state')}
              name="businessState"
              placeholder={t('please_enter_state')}
              control={control}
              error={!!errors['businessState']}
              helperText={
                !!errors['businessState']
                  ? `${errors['businessState'].message}`
                  : ''
              }
              requiredField
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <AutocompleteSelect
              requiredField
              id="BusinessLocation"
              isCountryField={true}
              labelText={t('business_location')}
              placeHolderText={t('business_location_placeholder')}
              name="businessLocation"
              disabled={true}
              control={control}
              error={!!errors['businessLocation']}
              setValue={setValue}
              helperText={
                !!errors['businessLocation']
                  ? `${errors['businessLocation'].message}`
                  : ''
              }
              options={[]}
              freeSolo={true}
              labelKey="name"
            />
          </Grid>
        </Grid>
        <div className="step_help flex">
          <p>
            {t('by_clicking_agree_and_continue')}
            <a
              onClick={() => {
                window.open(ROUTES.TERMS, '_blank');
              }}
              className="link"
            >
              {t('reeltrak_services_business_solutions_agreement')}
            </a>{' '}
            and{' '}
            <a
              onClick={() => {
                window.open(ROUTES.POLICIES, '_blank');
              }}
              className="link"
            >
              {t('reeltrak_privacy')}
            </a>
            .
          </p>
        </div>
        <Box className="buttonContainer">
          <CustomButton
            size="large"
            variant="contained"
            text={t('agree_and_continue')}
            showIcon={false}
            width="100%"
            type="submit"
            id="login"
            loading={load || apiStatus === ApiStatus.loading}
            disabled={!isValid}
          />
        </Box>
      </form>
    </div>
  );
}
